import React, { useState } from 'react';
import cx from 'classnames';

import styles from './index.module.css';
import Backdrop from '../../Backdrop';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/iconsend.svg';

interface IStoreCity {
	city?: string;
}

const StoreCity: React.FC<IStoreCity> = ({ city }) => {
	const [show, setShow] = useState(false);
	const hidePopup = () => {
		setShow(false);
	};

	return (
		<>
			<div className={styles.cityButtonWrapper}>
				<button
					className={styles.cityButton}
					type="button"
					onClick={() => {
						setShow(true);
					}}
				>
					<SendIcon />
					{city} Area
				</button>
			</div>

			<Backdrop show={show} onClick={hidePopup} />
			<div className={cx(styles.containerWrapper, { [styles.hide]: !show })}>
				<CloseIcon className={styles.close} onClick={hidePopup} />
				<div className={cx(styles.container)}>
					<div className={styles.image}>
						<SendIcon className={styles.sendIcon} />
					</div>
					<div className={styles.title}>Dukungan Pengiriman</div>
					<div className={styles.subTitle}>
						Pemesanan hanya mendukung kurir dalam kota area <span>{city}</span>
					</div>
				</div>
			</div>
		</>
	);
};

export default StoreCity;
