import Amplitude from 'amplitude-js'

const { REACT_APP_AMPLITUDE } = process.env

export const initAmplitude = () => {
  Amplitude.getInstance().init(REACT_APP_AMPLITUDE!)
}

export const setAmplitudeUserDevice = (installationToken: string) => {
  Amplitude.getInstance().setDeviceId(installationToken)
}

export const setAmplitudeUserId = (userId: string) => {
  Amplitude.getInstance().setUserId(userId)
}

export const setAmplitudeUserProperties = (properties: any) => {
  Amplitude.getInstance().setUserProperties(properties)
}

export const setAmplitudeData = (eventType: string, eventProperties?: any) => {
  Amplitude.getInstance().logEvent(eventType, eventProperties)
}