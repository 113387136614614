import React from 'react';

interface HomePageTxtErr {
	phoneNumber: string;
	statusApiError: string;
}

export const HomePageGenerateTextError: React.FC<HomePageTxtErr> = ({
	phoneNumber,
	statusApiError,
}) => {
	if (phoneNumber === 'invalid-format' || phoneNumber === 'invalid-phone-number') {
		return (
			<>
				Sepertinya ini <span>bukan nomor yang valid,</span> pastikan diawali +62xxxx, 62xxx
				atau 08xxx.
			</>
		);
	}

	if (phoneNumber === 'required') {
		return <>Wajib diisi</>;
	}

	if (statusApiError !== '') {
		return (
			<>
				Ups, sepertinya nomor ini <span>tidak memiliki</span> akun WhatsApp yang aktif. Coba
				nomor lain!
			</>
		);
	}

	return null;
};

interface AppStoreTxtErr {
	mobile: string;
}

export const AppStoreGenerateTextError: React.FC<AppStoreTxtErr> = ({ mobile }) => {
	if (mobile === 'invalid-format' || mobile === 'invalid-phone-number') {
		return <>Pastikan nomor diawali +62xxxx, 62xxx atau 08xxx</>;
	}

	if (mobile === 'required') {
		return <> Wajib diisi </>;
	}

	return null;
};
