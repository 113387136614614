import axios from 'axios';
import API, { AxiosCreateTO, TIMEOUTCHECKOUT } from './index';

const KINO_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const generateProductApiUrl = (storeId: string) => {
	return `${process.env.REACT_APP_API_BASE_URL}/store/${storeId}?token=`;
};

export const getProductAPI = async (storeId: string, token: string) => {
	return await API.get(`store/${storeId}`);
};

export const getProductAPI_WTO = async (storeId: string, token: string) => {
	return await AxiosCreateTO().get(`store/${storeId}`, {
		params: {
			token,
		},
	});
};

export const generateProductKinoApiUrl = () => {
	return `${KINO_BASE_URL}/kinostore/products`;
};

export const getKinoProduct = (token: string) => {
	return axios.get(generateProductKinoApiUrl(), {
		params: {
			token,
		},
	});
};

export const generateCategoryKinoApiUrl = () => {
	return `${KINO_BASE_URL}/kinostore/categories`;
};

export const getKinoCategory = (token: string) => {
	return axios.get(generateCategoryKinoApiUrl(), {
		params: {
			token,
		},
	});
};

export interface ResponseInputCity {
	city_id: string;
	province_id: string;
	province: string;
	type: string;
	city_name: string;
	postal_code: string;
}

export const getInputCity = async (term: string) => {
	return await API.post(`helpers/rajaongkir/autocomplete/city`, {
		term,
	});
};

interface IPostCart {
	token: string;
	cart: { 'product-code': string; quantity: number }[];
}

export interface IFormObject {
	name?: string;
	mobile?: string;
	address?: string;
	city?: string;
	notes?: string;
}

export const checkoutAPI = async (storeId: string, cart: IPostCart, formObject?: IFormObject) => {
	let body = cart;
	if (formObject) {
		body = {
			...body,
			...formObject,
		};
	}

	return await AxiosCreateTO(TIMEOUTCHECKOUT).post(`store/${storeId}/checkout`, body);
};
