import React from 'react';
import cx from 'classnames';
import styles from './index.module.css';
import { ICheckoutForm } from '../../../../../../interface/checkoutForm';

interface IProps {
	val: ICheckoutForm;
	answer: string;
	onChangeInput: (value: string) => void;
	isSubmit: boolean;
	error: string;
	no: number;
}

const TextareaForm: React.FC<IProps> = ({ val, answer, onChangeInput, isSubmit, error, no }) => {
	return (
		<>
			<div className={styles.formGroup} id={`no${no}`}>
				<label className={styles.title}>
					{val.title}
					{val.required && <span>*</span>}
				</label>
				{val.description && <label className={styles.subTitle}>{val.description}</label>}

				<textarea
					className={
						isSubmit && error === 'required'
							? cx('form-control', styles.errorWrapper)
							: 'form-control'
					}
					rows={3}
					placeholder={val.placeholder || ''}
					onChange={e => {
						onChangeInput(e.target.value);
					}}
					value={answer}
				/>
				{isSubmit && error === 'required' && (
					<div className={styles.txtError}>Wajib diisi</div>
				)}
			</div>
		</>
	);
};

export default TextareaForm;
