import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Highlighter from 'react-highlight-words';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as CloseBlack } from '../../assets/icons/closeBlack.svg';
import { IProducts } from '../../interface/products';
import styles from './index.module.css';
import CategoryDropdown from '../CategoryDropdown';

interface IProps {
	query: string;
	search: string;
	setSearch: (search: string) => void;
	actionSearch: (event: React.FormEvent<HTMLFormElement>) => void;
	actionSearchStr: (search: string) => void;
	actionClearSearch: (event: React.FormEvent<HTMLFormElement>) => void;
	clearSearch: () => void;
	categories?: string[];
	totalSearch: number;
	searchSuggestion?: IProducts[];
	themeType?: string;
}

const SearchBar: React.FC<IProps> = ({
	search,
	setSearch,
	actionSearch,
	categories,
	actionClearSearch,
	query,
	clearSearch,
	totalSearch,
	searchSuggestion,
	actionSearchStr,
	themeType,
}) => {
	const [isInputFocus, setInputFocus] = useState(false);

	useEffect(() => {
		if (query !== '') window.scrollTo(0, 0);
	}, [query]);

	const renderCategories = () => {
		if ((query && totalSearch <= 0) || isInputFocus) return <React.Fragment />;
		if (categories) return <CategoryDropdown categories={categories} />;

		return <React.Fragment />;
	};

	const renderSuggestion = () => {
		if (!isInputFocus || !searchSuggestion) return <React.Fragment />;
		const searchSplit = search.split(' ');
		return (
			<div className={styles.SuggestionWrapperContainer}>
				<div className={styles.suggestionContainer}>
					<ul>
						{searchSuggestion.map((data: IProducts) => {
							return data.items.map(val => {
								return (
									<li>
										<button
											type="button"
											onClick={() => {
												actionSearchStr(val.name);
											}}
										>
											<Highlighter
												className={styles.highlightClass}
												highlightClassName={styles.highlightWord}
												searchWords={searchSplit}
												autoEscape={true}
												textToHighlight={val.name}
											/>
										</button>
									</li>
								);
							});
						})}
					</ul>
				</div>
			</div>
		);
	};
	return (
		<>
			<div className={styles.wrapperContainer}>
				<form className={styles.container} onSubmit={actionSearch}>
					<label htmlFor="search" className={styles.search}>
						<input
							type="text"
							placeholder="Cari produk disini.."
							className={styles.input}
							id="search"
							value={search}
							autoComplete="off"
							onChange={event => setSearch(event.target.value)}
							onKeyDown={(e: any) => {
								if (e.key === 'Enter') {
									e.target.blur();
									actionSearch(e);
								}
							}}
							onFocus={() => {
								setInputFocus(true);
							}}
							onBlur={() => {
								setTimeout(() => {
									setInputFocus(false);
								}, 200);
							}}
						/>
						<button
							className={
								query === ''
									? cx(styles.searchButton, 'mtSearchIco')
									: styles.disableBtn
							}
						>
							<SearchIcon />
						</button>

						<button
							className={
								query !== ''
									? cx(styles.searchButton, 'mtClearSearchIco')
									: styles.disableBtn
							}
							onClick={() => {
								clearSearch();
							}}
						>
							<CloseBlack />
						</button>
					</label>
					{renderCategories()}
				</form>
			</div>
			{renderSuggestion()}
		</>
	);
};

export default SearchBar;
