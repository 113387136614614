import React from 'react';
import cx from 'classnames';
import ProductButton from '../ProductButton';
// import ProductPopup from '../ProductPopup';
import { ICart, IProduct } from '../../interface/products';
import { formatRp } from '../../utils/currency';
import styles from './index.module.css';

interface IProps {
	product: IProduct;
	cart: ICart;
	setCart: (cart: ICart) => void;
	onClickProduct: (product: any) => void;
	popup?: boolean;
}

const RenderPrice = (price: number, price_before_discount: number) => {
	if (price_before_discount && price_before_discount > 0)
		return (
			<h4 className={styles.normalPrice}>
				<span className={styles.priceSliced}>{`${formatRp(price_before_discount)}`}</span>
				<span className={styles.priceDiscount}>{`${formatRp(price)}`}</span>
				<span className={styles.promoWrapper}>Promo</span>
			</h4>
		);

	return <h4 className={styles.normalPrice}>{`${formatRp(price)}`}</h4>;
};

const RenderProductName = (name: string, price_before_discount: number) => {
	if (price_before_discount && price_before_discount > 0) {
		return (
			<div className={styles.productNameWithPromo}>
				<h3 className={styles.name}>
					{' '}
					<div dangerouslySetInnerHTML={{ __html: name }} />
				</h3>
				<span className={styles.promoStoreName}>Promo</span>
			</div>
		);
	}

	return (
		<h3 className={styles.name}>
			<div dangerouslySetInnerHTML={{ __html: name }} />
		</h3>
	);
};

const Product: React.FC<IProps> = ({ product, cart, setCart, popup = true, onClickProduct }) => {
	const { id, name, excerpt, price, image, availability, price_before_discount, stock } = product;

	// const [showPopup, setShowPopup] = useState(false);
	// const displayPopup = () => setShowPopup(true);
	// const hidePopup = () => setShowPopup(false);
	const discount_price = price_before_discount || 0;
	return (
		<>
			{/* <ProductPopup
				show={showPopup && popup}
				cart={cart}
				setCart={setCart}
				product={product}
				hidePopup={hidePopup}
			/> */}
			<div
				className={cx(
					styles.container,
					{ [styles.disabled]: !availability },
					'mtToPopUpProduct',
				)}
				onClick={() => {
					onClickProduct(product);
				}}
			>
				<div className={styles.image}>
					<img
						src={
							image && image['50x50']
								? image['50x50']
								: 'https://idgerras.sirv.com/ChatNShop/app/1-80/thumb-80.jpg'
						}
						alt=""
						onError={(e: any) => {
							e.target.onerror = null;
							e.target.src =
								'https://idgerras.sirv.com/ChatNShop/app/1-80/thumb-80.jpg';
						}}
					/>
				</div>
				<div className={styles.info}>
					{/* <h3 className={styles.name}>{name}</h3> */}
					{RenderProductName(name, discount_price)}
					<p className={styles.excerpt}>
						<div dangerouslySetInnerHTML={{ __html: excerpt }} />
					</p>
					<div className={styles.priceAndButton}>
						{/* <h4 className={styles.price}>{formatRp(price)}</h4> */}
						{RenderPrice(price, discount_price)}
						{availability ? (
							<ProductButton
								id={id}
								price={price}
								price_before_discount={discount_price}
								cart={cart}
								setCart={setCart}
								withClassName="mtAddCartHome"
								stock={stock}
							/>
						) : (
							<span className={styles.empty}>Kosong</span>
						)}
					</div>
					<hr className={styles.divider} />
				</div>
			</div>
		</>
	);
};

export default Product;
