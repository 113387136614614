import React from 'react';
// import Loadable from 'react-loadable';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// import HomePage from './pages/homepage';
import Store from './pages/store/basic';
// import BulkStore from './pages/store/bulk';

// const MyLoadingComponent: React.FC<any> = ({ isLoading, error }) => {
// 	if (isLoading) {
// 		return <React.Fragment />;
// 	} else if (error) {
// 		return <div>Sorry, there was a problem loading the page.</div>;
// 	} else {
// 		return null;
// 	}
// };

// const AsyncPricePage = Loadable({
// 	loader: () => import('./pages/homepage/price'),
// 	loading: MyLoadingComponent,
// });

// const AsyncTos = Loadable({
// 	loader: () => import('./pages/homepage/tos'),
// 	loading: MyLoadingComponent,
// });

// const AsyncPop = Loadable({
// 	loader: () => import('./pages/homepage/pop'),
// 	loading: MyLoadingComponent,
// });

// const AsyncFaq = Loadable({
// 	loader: () => import('./pages/homepage/faq'),
// 	loading: MyLoadingComponent,
// });

function App() {
	return (
		<>
			<Router>
				<Switch>
					<Route path="/" exact component={Store} />
					{/* <Route path="/store/basic/:storeId" component={Store} />
					<Route path="/store/bulk/:storeId" component={BulkStore} /> */}
					{/* Shortened url */}
					{/* <Route exact path="/kebijakan-privasi" component={AsyncPop} />
					<Route exact path="/ketentuan-layanan" component={AsyncTos} />
					<Route exact path="/harga" component={AsyncPricePage} />
					<Route exact path="/faq" component={AsyncFaq} /> */}
					{/* <Route exact path="/:storeId" component={Store} /> */}
					{/* <Route exact path="/b/:storeId" component={BulkStore} /> */}
				</Switch>
			</Router>
		</>
	);
}

export default App;
