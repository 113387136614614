import React, { useState } from 'react';
import cx from 'classnames';
import { ReactComponent as CategoryIcon } from '../../assets/icons/category.svg';
import styles from './index.module.css';

interface IProps {
	categories: string[];
}

const CategoryDropdown: React.FC<IProps> = ({ categories }) => {
	const [open, setOpen] = useState(false);

	return (
		<div className={styles.categoryWrapper}>
			<button
				className={cx(styles.categoryButton, 'mtBtnCategory')}
				onClick={() => setOpen(!open)}
			>
				<CategoryIcon /> Kategori
			</button>
			<div
				className={cx(styles.categoryDropdown, {
					[styles.categoryDropdownHide]: !open,
				})}
			>
				{categories.map((category, index) => (
					<React.Fragment key={category}>
						<p
							className={cx(styles.categoryItem, 'mtCategoryItem')}
							onClick={() => {
								document.getElementById(`${category}`)?.scrollIntoView({
									behavior: 'smooth',
									block: 'start',
								});
								setOpen(false);
							}}
						>
							<div dangerouslySetInnerHTML={{ __html: category }} />
						</p>
						{index + 1 < categories.length && <hr className={styles.divider} />}
					</React.Fragment>
				))}
			</div>
		</div>
	);
};

export default CategoryDropdown;
