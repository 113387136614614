import { Root as IChatNShop } from '../../../api/_interface/chatnshop';
import { Root as IKinoCategory } from '../../../api/_interface/kinoGetCategory';
import { Root as IKinoProduct } from '../../../api/_interface/konoGetProduct';

interface IFunc {
	(kinoProduct: IKinoProduct, kinoCategory: IKinoCategory): IChatNShop;
}

export const converterToChatNShop: IFunc = (
	kinoProduct: IKinoProduct,
	kinoCategory: IKinoCategory,
) => {
	const r: any = [];

	kinoCategory.categories.forEach(el => {
		r.push({
			id: el.id,
			category: el.title,
			items: [],
		});
	});

	kinoProduct.products.forEach(product => {
		product.category_ids.forEach(elIds => {
			const dataArray = {
				id: product.product_code,
				name: product.title,
				excerpt: product.short_description,
				availability: product.is_active && product.is_in_stock,
				stock: product.stock,
				price: product.price_raw,
				price_before_discount: product.usual_price_raw,
				image: {
					'50x50': product.detailed_images[0].url,
					'480x360': product.detailed_images[0].url,
				},
			};
			const findData = r.findIndex((f: any) => f.id === elIds);
			if (findData > -1) {
				r[findData].items.push(dataArray);
			}
		});
	});

	return {
		store_name: 'Kino Store',
		store_logo:
			'https://images.tokopedia.net/img/cache/215-square/shops-1/2017/7/20/2230885/2230885_23cf24d2-257b-4634-95e5-c18137c0f28a.png',
		store_logo_bg: '#ffffff',
		store_desc: 'Kino Store',
		results: r,
	};
};

export default converterToChatNShop;
