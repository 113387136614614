import React, { useState } from 'react';

import Product from '../Product';
import SimpleProduct from '../SimpleProduct';
import { IProducts, IProduct, ICart } from '../../interface/products';
import styles from './index.module.css';
import ProductPopup from '../ProductPopup';

interface IProps {
	products: IProducts[];
	cart: ICart;
	setCart: (cart: ICart) => void;
	bulk?: boolean;
}

const ProductGroup: React.FC<IProps> = ({ products, cart, setCart, bulk = false }) => {
	const [showProductPopup, setProductShowPopup] = useState(false);
	const [clickedProduct, setClickedProduct] = useState<IProduct>({
		id: '',
		name: '',
		excerpt: '',
		price: 0,
		availability: false,
		stock: 0,
	});
	const displayProductPopup = () => setProductShowPopup(true);
	const hideProductPopup = () => setProductShowPopup(false);
	const isAnyProduct = products && products.length > 0;
	return (
		<>
			{isAnyProduct &&
				products.map(product => (
					<div className={styles.container} key={product.id} id={product.category}>
						<h2 className={styles.title}>
							<div dangerouslySetInnerHTML={{ __html: product.category }} />
						</h2>
						{/* {product.items.map(item => (
					
							<ProductComponent
								key={item.id}
								product={item}
								cart={cart}
								setCart={setCart}
							/>
						)
						)} */}
						{product.items.map(item => {
							if (bulk) {
								return (
									<SimpleProduct
										key={item.id}
										product={item}
										cart={cart}
										setCart={setCart}
									/>
								);
							}

							return (
								<Product
									key={item.id}
									product={item}
									cart={cart}
									setCart={setCart}
									popup={false}
									onClickProduct={(product: any) => {
										setClickedProduct(product);
										displayProductPopup();
									}}
								/>
							);
						})}
					</div>
				))}

			{!bulk && isAnyProduct && (
				<ProductPopup
					show={showProductPopup}
					cart={cart}
					setCart={setCart}
					product={clickedProduct}
					hidePopup={hideProductPopup}
				/>
			)}
		</>
	);
};

export default ProductGroup;
