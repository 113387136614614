import { ThemeProps } from './index';

export const LightTheme: ThemeProps = {
	navBg: '#FAFAFA',
	searchBorderColor: '#E8E8E8',
	catBtnBg: '#FFFFFF',
	catBtnColor: '#2D9CDB',
	wrapperBg: '#FFFFFF',
	mainBg: '#E6EAEE',
	btnBg: '#2D9CDB',
	btnColor: '#FFFFFF',
	highlightTxtColor: '#219653',
	formBg: '#FAFAFA',
	formBorderBg: '#E8E8E8',
	primmaryTxtColor: '#333333',
	themeType: 'light', // dark or light
	redTextColor: '#EB5757',
};

export const DarkTheme: ThemeProps = {
	navBg: '#333333',
	searchBorderColor: '#0F0F0F',
	catBtnBg: '#000000',
	catBtnColor: '#4EA0E1',
	wrapperBg: '#0F0F0F',
	mainBg: '#202020',
	btnBg: '#066FC2',
	btnColor: '#EFEFEF',
	highlightTxtColor: '#74D778',
	formBg: '#333333',
	formBorderBg: '#333333',
	primmaryTxtColor: '#EFEFEF',
	themeType: 'dark', // dark or light
	redTextColor: '#EB5757',
};
