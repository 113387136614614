import React from 'react';
import cx from 'classnames';
import styles from './index.module.css';

const ReqTimeout: React.FC = () => (
	<div className={cx(styles.wrapper)} id="mtErrorPageTimeout">
		<div className={styles.container}>
			<div className={cx(styles.txtTitle, 'mtTimeOutPage')}>Waduh kelamaan?</div>
			<div className={styles.txtDesc}>
				Jangan khawatir, coba muat ulang lagi dan pastikan internet kamu stabil. Jika masih,
				coba hubungkan ulang internet kamu atau gunakan koneksi lain.
			</div>
			<button
				className={cx(styles.btnRetry, 'btnTimeoutReload')}
				type="button"
				onClick={() => {
					window.location.reload();
				}}
			>
				Muat Ulang
			</button>
		</div>
	</div>
);

export default ReqTimeout;
