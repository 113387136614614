import React from 'react';
import cx from 'classnames';

import ProductButton from '../ProductButton';
import { ICart, IProduct } from '../../interface/products';
import { formatRp } from '../../utils/currency';
import styles from './index.module.css';

interface IProps {
	product: IProduct;
	cart: ICart;
	setCart: (cart: ICart) => void;
}

const RenderPrice = (price: number, price_before_discount: number) => {
	if (price_before_discount && price_before_discount > 0)
		return (
			<h4 className={styles.priceWithDiscount}>
				<span className={styles.priceSliced}>{`${formatRp(price_before_discount)}`}</span>
				<span className={styles.priceDiscount}>{`${formatRp(price)}`}</span>
				<span className={styles.promoWrapper}>Promo</span>
			</h4>
		);

	return <h4 className={styles.price}>{`${formatRp(price)}`}</h4>;
};

const SimpleProduct: React.FC<IProps> = ({ product, cart, setCart }) => {
	const { id, name, excerpt, price, availability, price_before_discount, stock } = product;
	const discount_price = price_before_discount || 0;
	return (
		<>
			<div className={cx(styles.container, { [styles.disabled]: !availability })}>
				<div className={styles.leftSide}>
					<h3 className={styles.name}>{name}</h3>
					<div className={styles.description}>
						<p className={styles.id}>{id}</p>
						<p className={styles.excerpt}>{excerpt}</p>
					</div>
				</div>
				<div className={styles.rightSide}>
					{RenderPrice(price, price_before_discount || 0)}
					{availability ? (
						<ProductButton
							id={id}
							price={price}
							cart={cart}
							setCart={setCart}
							price_before_discount={discount_price}
							stock={stock}
						/>
					) : (
						<span className={styles.empty}>Kosong</span>
					)}
				</div>
			</div>
			<hr className={styles.divider} />
		</>
	);
};

export default SimpleProduct;
