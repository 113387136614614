import { ObjectLiteral } from '../hooks/useForm';

export const phoneValidation = (phoneNumber: string) => {
	if (phoneNumber === '') {
		return 'required';
	}
	const regexPhone = RegExp(`^[0-9+-\\s]+$`);
	if (!regexPhone.test(phoneNumber)) return 'invalid-phone-number';

	if (
		phoneNumber.substring(0, 2) !== '08' &&
		phoneNumber.substring(0, 2) !== '62' &&
		phoneNumber.substring(0, 3) !== '+62'
	) {
		return 'invalid-phone-number';
	}
	return '';
};

export const homePageForm = (errValue: ObjectLiteral, formValue: ObjectLiteral) => {
	let isAnyError = false;

	const validation = phoneValidation(formValue.phoneNumber);
	if (validation !== '') {
		isAnyError = true;
		errValue.phoneNumber = validation;
	}
	return { errValue, isAnyError };
};

export const homePageIsErrorForm = (phoneNumber: string, statusApiError: string) => {
	return (
		phoneNumber === 'invalid-format' ||
		phoneNumber === 'invalid-phone-number' ||
		phoneNumber === 'required' ||
		statusApiError !== ''
	);
};

export const appStorePhoneForm = (errValue: ObjectLiteral, formValue: ObjectLiteral) => {
	let isAnyError = false;

	const validation = phoneValidation(formValue.mobile);
	if (validation !== '') {
		isAnyError = true;
		errValue.mobile = validation;
	}

	return { errValue, isAnyError };
};

export const appStoreIsErrorFormOnChange = (mobile: string, valueMobile: string) => {
	return (
		(mobile === 'invalid-format' ||
			mobile === 'invalid-phone-number' ||
			mobile === 'required') &&
		valueMobile
	);
};

export const appStoreIsErrorForm = (mobile: string, isOnSubmit: boolean) => {
	return (
		(mobile === 'invalid-format' ||
			mobile === 'invalid-phone-number' ||
			mobile === 'required') &&
		isOnSubmit
	);
};
