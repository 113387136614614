import React from 'react';
import cx from 'classnames';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as ArrowBack } from '../../assets/icons/arrowBack.svg';
import styles from './index.module.css';

interface IProps {
	clearSearch: () => void;
	isClearSearch?: boolean;
}

const Footer: React.FC<IProps> = ({ clearSearch, isClearSearch = false }) => {
	function actionFocusToSearch() {
		document.getElementById('search')?.focus();
	}

	if (isClearSearch) {
		return (
			<div className={styles.wrapperContainer}>
				<footer
					className={cx(styles.container, 'mtBackSearch')}
					onClick={() => {
						clearSearch();
					}}
				>
					<ArrowBack className={styles.icon} />
					<div className={styles.text}>
						<h5 className={styles.title}>Sudah mencari produknya ?</h5>
						<p className={styles.subtitle}>Klik disini untuk melihat semua produk</p>
					</div>
				</footer>
			</div>
		);
	}

	return (
		<div className={styles.wrapperContainer}>
			<footer
				className={cx(styles.container, 'mtToSearch')}
				onClick={() => {
					actionFocusToSearch();
				}}
			>
				<SearchIcon className={styles.icon} />
				<div className={styles.text}>
					<h5 className={styles.title}>Belum ketemu barang yang dicari ?</h5>
					<p className={styles.subtitle}>Klik disini untuk mencari</p>
				</div>
			</footer>
		</div>
	);
};

export default Footer;
