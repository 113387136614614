import React from 'react';
import styles from './index.module.css';
import { ICheckoutForm } from '../../../../../../interface/checkoutForm';

interface IProps {
	val: ICheckoutForm;
	multiple_answer: string[];
	onChangeInput: (data: string) => void;
	onChangeDeleteInput: (data: string) => void;
	isSubmit: boolean;
	error: string;
	no: number;
}

const CheckboxForm: React.FC<IProps> = ({
	val,
	multiple_answer,
	onChangeInput,
	onChangeDeleteInput,
	isSubmit,
	error,
	no,
}) => {
	return (
		<>
			<div className={styles.formGroup} id={`no${no}`}>
				<label className={styles.title}>
					{val.title}
					{val.required && <span>*</span>}
				</label>
				{val.description && <label className={styles.subTitle}>{val.description}</label>}

				{val.value &&
					val.value.length > 0 &&
					val.value.map(v => {
						const findByValue = multiple_answer.find(el => {
							return el === v;
						});

						let checked = false;
						if (findByValue) checked = true;

						return (
							<label className={styles.container}>
								{v}
								<input
									type="checkbox"
									checked={checked}
									value={v}
									onClick={() => {
										if (findByValue) {
											onChangeDeleteInput(v);
										} else {
											onChangeInput(v);
										}
									}}
								/>
								<span className={styles.checkmark} />
							</label>
						);
					})}
				{isSubmit && error === 'required' && (
					<div className={styles.txtError}>Wajib diisi</div>
				)}
			</div>
		</>
	);
};

export default CheckboxForm;
