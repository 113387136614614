import React from 'react';
import cx from 'classnames';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ICart } from '../../interface/products';
import styles from './index.module.css';

interface IProps {
	id: string;
	price: number;
	price_before_discount: number;
	cart: ICart;
	setCart: (cart: ICart) => void;
	untilZero?: boolean;
	withClassName?: string;
	stock: number;
}

const ProductButton: React.FC<IProps> = ({
	id,
	price,
	cart,
	setCart,
	price_before_discount,
	untilZero = false,
	withClassName,
	stock,
}) => {
	price_before_discount = !price_before_discount ? price : price_before_discount;
	const addProduct = () => {
		if (cart.items[id]) {
			const addedStock = cart.items[id] + 1;
			if (addedStock <= stock) {
				setCart({
					...cart,
					totalItems: cart.totalItems + 1,
					totalPrice: cart.totalPrice + price,
					totalPriceBeforeDiscount: cart.totalPriceBeforeDiscount + price_before_discount,

					items: { ...cart.items, [id]: cart.items[id] + 1 },
				});
			}
		} else {
			setCart({
				...cart,
				totalItems: cart.totalItems + 1,
				totalPrice: cart.totalPrice + price,
				totalPriceBeforeDiscount: cart.totalPriceBeforeDiscount + price_before_discount,
				items: { ...cart.items, [id]: 1 },
			});
		}
	};

	const subProduct = () => {
		if (cart.items[id] > 1) {
			setCart({
				...cart,
				totalItems: cart.totalItems - 1,
				totalPrice: cart.totalPrice - price,
				totalPriceBeforeDiscount: cart.totalPriceBeforeDiscount - price_before_discount,
				items: { ...cart.items, [id]: cart.items[id] - 1 },
			});
		} else {
			removeProduct();
		}
	};

	const removeProduct = () => {
		if (untilZero) {
			setCart({
				...cart,
				totalItems: cart.totalItems - cart.items[id],
				totalPrice: cart.totalPrice - cart.items[id] * price,
				totalPriceBeforeDiscount:
					cart.totalPriceBeforeDiscount - cart.items[id] * price_before_discount,
				items: { ...cart.items, [id]: 0 },
			});
		} else {
			const copyCartItems = { ...cart.items };
			delete copyCartItems[id];
			setCart({
				...cart,
				totalItems: cart.totalItems - cart.items[id],
				totalPrice: cart.totalPrice - cart.items[id] * price,
				totalPriceBeforeDiscount:
					cart.totalPriceBeforeDiscount - cart.items[id] * price_before_discount,
				items: { ...copyCartItems },
			});
		}
	};

	const renderButtonQty = () => {
		if (cart.items[id] > 1) {
			return (
				<div
					className={styles.quantityButtonContainer}
					onClick={event => event.stopPropagation()}
				>
					<DeleteIcon
						className={cx(
							styles.deleteIcon,
							{
								[styles.iconDisabled]: untilZero && cart.items[id] === 0,
							},
							withClassName ? `${withClassName}remove` : '',
						)}
						onClick={removeProduct}
					/>
					<div className={styles.quantityButton}>
						<button
							className={cx(
								styles.decrement,
								withClassName ? `${withClassName}min` : '',
							)}
							onClick={subProduct}
							disabled={untilZero && cart.items[id] === 0}
						>
							-
						</button>
						<div className={styles.quantityTextLong}>{cart.items[id]}</div>
						{cart.items[id] < stock && (
							<button
								className={cx(
									styles.increment,
									withClassName ? `${withClassName}plus` : '',
								)}
								onClick={addProduct}
							>
								+
							</button>
						)}
					</div>
				</div>
			);
		}

		return (
			<div
				className={styles.quantityButtonContainer}
				onClick={event => event.stopPropagation()}
			>
				<DeleteIcon
					className={cx(
						styles.deleteIcon,
						{
							[styles.iconDisabled]: untilZero && cart.items[id] === 0,
						},
						withClassName ? `${withClassName}remove` : '',
					)}
					onClick={removeProduct}
				/>
				<div className={styles.quantityText}>{cart.items[id]}</div>
				<button
					className={cx(styles.increment, withClassName ? `${withClassName}plus` : '')}
					onClick={addProduct}
				>
					+
				</button>
			</div>
		);
	};

	if (cart.items[id] === undefined) {
		const addNewItem = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			event.stopPropagation();
			addProduct();
		};

		return (
			<button
				className={cx(styles.addButton, withClassName ? withClassName : '')}
				onClick={addNewItem}
			>
				+ Tambah
			</button>
		);
	}

	return renderButtonQty();
};

export default ProductButton;
