import axios from 'axios';

export const getInputKelurahan = async (kota: string, q: string) => {
	return await axios.get('https://50k55d5co2.execute-api.us-east-1.amazonaws.com/dev/kelurahan', {
		params: {
			kota,
			q,
		},
	});
};

export interface ResponseInputKelurahan {
	results: Result[];
}

export interface Result {
	sk: string;
	kota: string;
	kecamatan: string;
	pk: string;
	provinsi: string;
}
