import React, { useState, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import cx from 'classnames';

// import { ReactComponent as BrandingLogo } from '../../assets/brand.svg';
import { ReactComponent as BrandingLogoChatbiz } from '../../assets/brand-chatbiz.svg';
import { ReactComponent as PlaceIcon } from '../../assets/icons/place.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrowDown.svg';
import { ISocialLink } from '../../interface/socialLink';
import styles from './index.module.css';
import { trimOn } from '../../utils/text';
import ComponentStoreCity from './StoreCity';

interface IProps {
	total: number;
	query: string;
	storeName: string;
	error: string;
	token: string;
	storeDesc?: string;
	storeLogo?: string;
	storeLogoBG?: string;
	themeType?: string;
	socialLink?: ISocialLink[];
	storeAddress?: string;
	storeCity?: string;
	inCart: boolean;
}

const StoreHeader: React.FC<IProps> = ({
	total,
	query,
	storeName,
	error,
	token,
	storeDesc,
	storeLogo,
	storeLogoBG,
	themeType,
	storeCity,
	storeAddress,
	socialLink,
	inCart,
}) => {
	const backgroundColor = themeType === 'dark' ? '#808080' : '#B2B2B2';

	const [show, setShow] = useState<boolean>(!inCart);

	useEffect(() => {
		if (inCart) {
			setShow(false);
		}
	}, [inCart]);

	const renderHeader = () => {
		if (!storeName && !error) {
			return (
				<ContentLoader speed={0.5} height={29} backgroundColor={backgroundColor}>
					<rect x="0" y="0" rx="10" ry="10" width="75%" height="29" />
				</ContentLoader>
			);
		}
		if (query) {
			return (
				<p className={styles.result}>
					<span>{total}</span> hasil <span>"{trimOn(query, 8)}"</span>
				</p>
			);
		}
		return <h1 className={styles.title}>{storeName}</h1>;
	};

	const renderWrapper = () => {
		const renderDesc = () => {
			if (!storeName && !error) {
				return (
					<ContentLoader speed={0.5} height={29} backgroundColor={backgroundColor}>
						<rect x="0" y="0" rx="10" ry="10" width="75%" height="29" />
					</ContentLoader>
				);
			}
			return <div className={styles.headerDesc}>{storeDesc}</div>;
		};

		const renderChatNSHopLogo = () => {
			if (themeType === 'dark') {
				return (
					<img
						src="https://seiturju.sirv.com/Chatbiz/app-Chatnshop-dark.svg"
						alt="logo chatnshop"
					/>
				);
			}

			return (
				<img
					src="https://seiturju.sirv.com/Chatbiz/app-Chatnshop-light.svg"
					alt="logo chatnshop"
				/>
			);
		};

		const renderInfoCity = () => {
			return <ComponentStoreCity city={storeCity} />;
		};

		const renderStoreAddress = () => {
			return (
				<div className={styles.addressWrapper}>
					<PlaceIcon />

					{storeAddress}
				</div>
			);
		};

		const renderStoreSocial = () => {
			if (!socialLink || socialLink.length === 0) return <React.Fragment />;

			return (
				<>
					<ul
						className={cx(
							styles.listSocial,
							{ [styles.hide]: !show },
							{ [styles.listSocialFull]: socialLink.length === 4 },
						)}
					>
						{socialLink.map(val => {
							return (
								<li>
									<a href={val.url} target="__blank">
										{val.name}
									</a>
								</li>
							);
						})}
					</ul>
					<div
						className={cx(styles.listSocial, styles.listSocialArrow, {
							[styles.hide]: show,
						})}
						onClick={() => {
							setShow(true);
						}}
					>
						<ArrowDownIcon />
					</div>
				</>
			);
		};

		return (
			<div className={cx(styles.headerWrapper, storeLogo ? styles.pushUp : styles.pushDown)}>
				<div className={styles.header}>
					{renderHeader()}
					{token ? <BrandingLogoChatbiz /> : renderChatNSHopLogo()}
				</div>
				{!query && storeCity && renderInfoCity()}
				{!query && storeDesc && renderDesc()}
				{!query && storeAddress && renderStoreAddress()}
				{!query && socialLink && renderStoreSocial()}
			</div>
		);
	};

	const renderLogoWrapper = () => {
		if (!storeLogo) return <React.Fragment />;
		return (
			<div
				className={styles.storeLogoWrapper}
				style={{
					backgroundColor: storeLogoBG ? storeLogoBG : 'transparent',
				}}
			>
				<img src={storeLogo} alt={storeName} />
			</div>
		);
	};

	return (
		<div>
			{renderLogoWrapper()}
			{renderWrapper()}
		</div>
	);
};

export default StoreHeader;
