import React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';

import animateScrollTo from 'animated-scroll-to';

import { ReactComponent as InfoIcon } from '../../../../assets/icons/calendar.svg';
import styles from '../index.module.css';
import { ICheckoutForm } from '../../../../interface/checkoutForm';

import TypeCheckbox from './Type/Checkbox';
import TypeDatepicker from './Type/Datepicker';
import TypeInput from './Type/Input';
import TypeRadio from './Type/Radio';
import TypeSelectBox from './Type/Selectbox';
import TypeTextarea from './Type/Textarea';

interface IDynamicState {
	no: number;
	title: string;
	required: boolean;
	type: string;
	answer: string;
	multiple_answer: string[];
	error: string;
}

interface IFormCheckoutProps {
	checkoutForm: ICheckoutForm[];
	onSubmit: (data: any) => void;
}

interface IFormCheckoutState {
	dynamicState: IDynamicState[];
	isSubmit: boolean;
	showFloatError: boolean;
}

class FormCheckout extends React.Component<IFormCheckoutProps, IFormCheckoutState> {
	state = {
		dynamicState: [
			{
				no: 0,
				title: '',
				type: '',
				required: false,
				answer: '',
				multiple_answer: [''],
				error: '',
			},
		],
		isSubmit: false,
		showFloatError: false,
	};

	componentDidMount() {
		this.setInitialDynamicState();
	}

	componentDidUpdate(prevProps: IFormCheckoutProps, prevState: IFormCheckoutState) {
		if (prevProps.checkoutForm !== this.props.checkoutForm) {
			this.setInitialDynamicState();
		}
		if (prevState.showFloatError !== this.state.showFloatError && this.state.showFloatError) {
			setTimeout(() => {
				this.setState({
					showFloatError: false,
				});
			}, 3000);
		}
	}

	setInitialDynamicState = () => {
		const { checkoutForm } = this.props;
		const setStete: IDynamicState[] = checkoutForm.map((v: ICheckoutForm, i: number) => {
			return {
				no: i + 1,
				title: v.title,
				required: v.required,
				type: v.type,
				answer: '',
				multiple_answer: [],
				error: '',
			};
		});
		this.setState({
			dynamicState: setStete,
		});
	};

	cleanSubmitFormState = () => {
		const { dynamicState } = this.state;
		const newData = dynamicState.map((v: IDynamicState) => {
			if (v.type === 'checkbox') {
				return {
					no: v.no,
					title: v.title,
					multiple_answer: v.multiple_answer,
					required: v.required,
					type: v.type,
				};
			} else if (v.type === 'datepicker') {
				return {
					no: v.no,
					title: v.title,
					answer: format(new Date(v.answer), 'dd/MM/yyyy'),
					required: v.required,
					type: v.type,
				};
			} else {
				return {
					no: v.no,
					title: v.title,
					answer: v.answer,
					required: v.required,
					type: v.type,
				};
			}
		});

		return newData;
	};

	onSubmitForm = () => {
		const { dynamicState } = this.state;
		let isAnyError = false;
		let arrNoError: number[] = [];

		const newData = dynamicState.map((v: IDynamicState) => {
			if (v.required && v.type === 'checkbox') {
				if (v.multiple_answer.length === 0) {
					isAnyError = true;
					arrNoError.push(v.no);
					return {
						...v,
						error: 'required',
					};
				}
				return v;
			} else if (v.required) {
				if (v.answer === '') {
					isAnyError = true;
					arrNoError.push(v.no);
					return {
						...v,
						error: 'required',
					};
				}
				return v;
			}
			return v;
		});

		if (isAnyError) {
			if (arrNoError.length > 0) {
				animateScrollTo(document.querySelector(`#no${arrNoError[0]}`), {
					elementToScroll: document.getElementById('form-custom-checkout'),
				});
			}
			this.setState({
				isSubmit: true,
				showFloatError: true,
				dynamicState: newData,
			});
		} else {
			this.props.onSubmit(this.cleanSubmitFormState());
		}
	};

	onChangeAnswer = (data: string, index: number) => {
		const { dynamicState, isSubmit } = this.state;
		let newData = dynamicState;
		newData[index].answer = data;

		if (isSubmit && newData[index].required) {
			if (newData[index].answer === '') {
				newData[index].error = 'required';
			} else {
				newData[index].error = '';
			}
		}

		this.setState({
			dynamicState: newData,
		});
	};

	onInsertMultiple = (text: string, index: number) => {
		const { dynamicState, isSubmit } = this.state;
		const data = [text];
		const oldData = dynamicState[index].multiple_answer;

		let newData = dynamicState;
		newData[index].multiple_answer = oldData.concat(data);

		if (isSubmit && newData[index].required) {
			if (newData[index].multiple_answer.length === 0) {
				newData[index].error = 'required';
			} else {
				newData[index].error = '';
			}
		}

		this.setState({
			dynamicState: newData,
		});
	};

	onDeleteMultiple = (text: string, index: number) => {
		const { dynamicState, isSubmit } = this.state;
		const oldData = dynamicState[index].multiple_answer;
		oldData.splice(oldData.indexOf(text), 1);

		let newData = dynamicState;
		newData[index].multiple_answer = oldData;

		if (isSubmit && newData[index].required) {
			if (newData[index].multiple_answer.length === 0) {
				newData[index].error = 'required';
			} else {
				newData[index].error = '';
			}
		}

		this.setState({
			dynamicState: newData,
		});
	};

	renderForm = () => {
		const { checkoutForm } = this.props;
		const { dynamicState, isSubmit } = this.state;
		if (dynamicState.length !== checkoutForm.length) return <React.Fragment />;

		return checkoutForm.map((val: ICheckoutForm, index: number) => {
			switch (val.type) {
				case 'datepicker':
					return (
						<TypeDatepicker
							val={val}
							answer={dynamicState[index].answer}
							onChange={data => {
								this.onChangeAnswer(data, index);
							}}
							isSubmit={isSubmit}
							error={dynamicState[index].error}
							no={dynamicState[index].no}
						/>
					);

				case 'checkbox':
					return (
						<TypeCheckbox
							val={val}
							multiple_answer={dynamicState[index].multiple_answer}
							onChangeInput={text => {
								this.onInsertMultiple(text, index);
							}}
							onChangeDeleteInput={text => {
								this.onDeleteMultiple(text, index);
							}}
							isSubmit={isSubmit}
							error={dynamicState[index].error}
							no={dynamicState[index].no}
						/>
					);

				case 'input':
					return (
						<TypeInput
							val={val}
							answer={dynamicState[index].answer}
							onChangeInput={data => {
								this.onChangeAnswer(data, index);
							}}
							isSubmit={isSubmit}
							error={dynamicState[index].error}
							no={dynamicState[index].no}
						/>
					);

				case 'textarea':
					return (
						<TypeTextarea
							val={val}
							answer={dynamicState[index].answer}
							onChangeInput={data => {
								this.onChangeAnswer(data, index);
							}}
							isSubmit={isSubmit}
							error={dynamicState[index].error}
							no={dynamicState[index].no}
						/>
					);

				case 'radio':
					return (
						<TypeRadio
							val={val}
							no={dynamicState[index].no}
							answer={dynamicState[index].answer}
							onChangeInput={data => {
								this.onChangeAnswer(data, index);
							}}
							isSubmit={isSubmit}
							error={dynamicState[index].error}
						/>
					);

				case 'selectbox':
					return (
						<TypeSelectBox
							val={val}
							answer={dynamicState[index].answer}
							onChangeInput={data => {
								this.onChangeAnswer(data, index);
							}}
							isSubmit={isSubmit}
							error={dynamicState[index].error}
							no={dynamicState[index].no}
						/>
					);

				default:
					return <React.Fragment />;
			}
		});
	};

	render() {
		return (
			<form className={cx(styles.formContainer, 'mtFormUserData')} id="form-custom-checkout">
				{this.state.showFloatError && (
					<div className={styles.floatingError}>Form wajib diisi, silakan lengkapi</div>
				)}
				<div className={styles.infoContainer}>
					<InfoIcon className={styles.infoIcon} />
					<div className={styles.infoText}>
						<div className={styles.infoTitle}>Langkah terakhir</div>
						<div className={styles.infoDesc}>
							Penjual membutuhkan informasi tambahan nih, silakan diisi ya.
						</div>
					</div>
				</div>
				<div className={styles.requiredText}> * Wajib diisi</div>
				{this.renderForm()}
				<div className={styles.btnContainer}>
					<button
						type="button"
						className={cx(
							styles.submitButton,
							'mtFormCheckoutConfirm',
							styles.submitButtonFull,
						)}
						onClick={this.onSubmitForm}
					>
						Pesan Sekarang
					</button>
				</div>
			</form>
		);
	}
}

export default FormCheckout;
