import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import ProductButton from '../ProductButton';
import Backdrop from '../Backdrop';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

import { IProduct, ICart } from '../../interface/products';
import { formatRp } from '../../utils/currency';
import styles from './index.module.css';

interface IProps {
	product: IProduct;
	cart: ICart;
	setCart: (cart: ICart) => void;
	hidePopup: () => void;
	show: boolean;
}

const RenderPrice = (price: number, price_before_discount: number) => {
	if (price_before_discount && price_before_discount > 0)
		return (
			<h4 className={styles.normalPrice}>
				<span className={styles.priceSliced}>{`${formatRp(price_before_discount)}`}</span>
				<span className={styles.priceDiscount}>{`${formatRp(price)}`}</span>
				<span className={styles.promoWrapper}>Promo</span>
			</h4>
		);

	return <h4 className={styles.normalPrice}>{`${formatRp(price)}`}</h4>;
};

const ProductPopup: React.FC<IProps> = ({ show, product, cart, setCart, hidePopup }) => {
	const {
		id,
		image,
		description,
		name,
		excerpt,
		price,
		availability,
		price_before_discount,
		stock,
	} = product;
	const discount_price = price_before_discount || 0;
	const [copyCart, setCopyCart] = useState<ICart>({
		storeId: '',
		token: '',
		totalItems: 0,
		totalPrice: 0,
		totalPriceBeforeDiscount: 0,
		items: {},
	});

	const actionMergeCart = () => {
		const mergeCartItems = {
			...cart.items,
			...copyCart.items,
		};

		for (let key in mergeCartItems) {
			if (mergeCartItems[key] === 0) {
				delete mergeCartItems[key];
			}
		}

		const mergeCart: ICart = {
			...cart,
			totalItems: copyCart.totalItems,
			totalPrice: copyCart.totalPrice,
			items: mergeCartItems,
		};
		setCart(mergeCart);
	};

	useEffect(() => {
		if (show) {
			setCopyCart(JSON.parse(JSON.stringify(cart)));
			if (!cart.items[id]) {
				setCopyCart({
					...cart,
					totalItems: cart.totalItems + 1,
					totalPrice: cart.totalPrice + price,
					items: { ...cart.items, [id]: 1 },
				});
			}
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [show]);

	const renderFooter = () => {
		if (!availability)
			return (
				<div className={styles.buttonContainer} onClick={hidePopup}>
					<div className={styles.txtEmpty}>Stok saat ini kosong</div>
					<button
						className={cx(styles.closeButton, 'mtCloseProductPopUp')}
						onClick={hidePopup}
					>
						Tutup
					</button>
				</div>
			);

		const renderProductButton = () => {
			if (copyCart.items[id] <= 1) {
				return (
					<>
						<div className={styles.qtyButtonWrapper}>
							<div className={styles.qtyText}>Qty : </div>
							<ProductButton
								id={id}
								withClassName="mtProductPopUp"
								price={price}
								cart={copyCart}
								setCart={setCopyCart}
								price_before_discount={discount_price}
								untilZero
								stock={stock}
							/>
						</div>
						{renderButton()}
					</>
				);
			}

			return (
				<>
					<ProductButton
						id={id}
						withClassName="mtProductPopUp"
						price={price}
						price_before_discount={discount_price}
						cart={copyCart}
						setCart={setCopyCart}
						untilZero
						stock={stock}
					/>
					{renderButton()}
				</>
			);
		};
		return (
			<div className={styles.buttonContainer} onClick={hidePopup}>
				{renderProductButton()}
			</div>
		);
	};

	const renderButton = () => {
		if (!cart.items[id] && copyCart.items[id] > 0) {
			return (
				<button
					className={cx(styles.addButton, 'mtPopUpFooterAdd')}
					onClick={actionMergeCart}
				>
					+Tambah
				</button>
			);
		}
		if (cart.items[id] > 0 && copyCart.items[id]) {
			return (
				<button
					className={cx(styles.addButton, 'mtPopUpFooterUpdate')}
					onClick={actionMergeCart}
				>
					Update
				</button>
			);
		}
		if (cart.items[id] > 0 && copyCart.items[id] === 0) {
			return (
				<button
					className={cx(
						[styles.addButton, styles.delete].join(' '),
						'mtPopUpFooterRemove',
					)}
					onClick={actionMergeCart}
				>
					Hapus
				</button>
			);
		}
		if (!cart.items[id] && copyCart.items[id] === 0) {
			return (
				<button className={cx(styles.addButton, 'mtPopUpFooterBack')} onClick={hidePopup}>
					Kembali
				</button>
			);
		}
	};

	return (
		<>
			<Backdrop show={show} onClick={hidePopup} />
			<div className={cx(styles.containerWrapper, { [styles.hide]: !show })}>
				<CloseIcon className={styles.close} onClick={hidePopup} />
				<div className={cx(styles.container)}>
					<div className={styles.image}>
						<img
							src={
								image && image['480x360']
									? image['480x360']
									: 'https://idgerras.sirv.com/ChatNShop/app/1-80/1-80.jpg'
							}
							alt=""
							onError={(e: any) => {
								e.target.onerror = null;
								e.target.src =
									'https://idgerras.sirv.com/ChatNShop/app/1-80/1-80.jpg';
							}}
						/>
					</div>
					<div className={styles.nameAndPrice}>
						<h3 className={styles.name}>{name}</h3>
					</div>
					<p className={styles.excerpt}>{excerpt}</p>
					<p className={styles.description}>
						{description &&
							description.split('\n').map((item, i) => {
								return <p key={i}>{item}</p>;
							})}
					</p>

					{RenderPrice(price, discount_price)}
					{renderFooter()}
				</div>
			</div>
		</>
	);
};

export default ProductPopup;
