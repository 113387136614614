export const constanstMessage = {
	STORE_PHONE_NUMBER: 'STORE_PHONE_NUMBER',
	SHOP_STORE_NAME: 'SHOP_STORE_NAME',
	SHOP_STORE_PHONE_NUMBER: 'SHOP_STORE_PHONE_NUMBER',
	SHOP_STORE_SUB_DISTRICT_SELECT_OPT: 'SHOP_STORE_SUB_DISTRICT_SELECT_OPT5',
	SHOP_STORE_ADDRESS: 'SHOP_STORE_ADDRESS',
	CART_ITEM_STORE: 'CART_ITEM_STORE_',
};

export const setItem = (key: string, data: any) => {
	localStorage.setItem(key, data);
};

export const getItem = (key: string) => {
	return localStorage.getItem(key);
};

export const removeItem = (key: string) => {
	localStorage.removeItem(key);
};

export const clear = () => {
	localStorage.clear();
};

export const setCartItem = (store: string, data: string) => {
	localStorage.setItem(`${constanstMessage.CART_ITEM_STORE}${store}`, data);
};

export const getCartItem = (store: string) => {
	return localStorage.getItem(`${constanstMessage.CART_ITEM_STORE}${store}`);
};

export const removeCartItem = (store: string) => {
	return localStorage.removeItem(`${constanstMessage.CART_ITEM_STORE}${store}`);
};
