import React, { useState, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import cx from 'classnames';
import styles from './index.module.css';
import ReqTimeout from '../ErrorPage/Timeout';
import { TIMEOUT } from '../../api';
const COUNTNUM = TIMEOUT / 1000;

const withTime = (WrappedComponent: React.ComponentClass | React.ElementType) => {
	interface IHOC {
		themeType?: string;
	}

	const HOC: React.FC<IHOC> = ({ themeType }) => {
		const [time, setTime] = useState<number>(0);
		useEffect(() => {
			if (time === COUNTNUM) return;

			const intervalId = setInterval(() => {
				setTime(time + 1);
			}, 1000);

			return () => clearInterval(intervalId);
		}, [time]);

		if (time >= COUNTNUM) return <ReqTimeout />;
		const backgroundColor = themeType === 'dark' ? '#808080' : '#B2B2B2';
		return (
			<>
				<WrappedComponent time={time} backgroundColor={backgroundColor} />
			</>
		);
	};

	return HOC;
};

export const SkeletonViewBasic = withTime(({ time, backgroundColor }) => {
	const renderInfo = () => {
		if (time >= 15 && time <= 45)
			return (
				<p className={cx(styles.loadingText, 'mtLoading15Sec')}>
					Sebentar, lebih lama dari biasanya..
				</p>
			);
		if (time >= 45 && time < COUNTNUM)
			return (
				<>
					<p className={cx(styles.loadingText, 'mtLoading45Sec')}>
						Sabar ya, masih memuat....
					</p>
					<p className={styles.loadingTextDesc}>
						Terlalu lama ?
						<button
							className={cx(styles.btnLink, 'mtTryReload')}
							type="button"
							onClick={() => {
								window.location.reload();
							}}
						>
							Coba muat ulang
						</button>
					</p>
				</>
			);

		return <p className={styles.loadingText}>Sedang Memuat...</p>;
	};
	return (
		<>
			<div className={styles.loadingContainer}>
				<ContentLoader
					speed={0.5}
					height={300}
					width="100%"
					backgroundColor={backgroundColor}
				>
					<rect x="0" y="0" rx="10" ry="10" width="40%" height="24" />
					{/* First Item */}
					<rect x="0" y={45} rx="10" ry="10" width={48} height="48" />
					<rect x="60" y={45} rx="10" ry="10" width="40%" height="19" />
					<rect x="60" y={75} rx="7.5" ry="7.5" width="25%" height="15" />
					<rect x="60" y={100} rx="7.5" ry="7.5" width="25%" height="15" />
					<rect x="77.5%" y={90} rx="15" ry="15" width="22.5%" height="29" />
					{/* Second side */}
					<rect x="0" y={135} rx="10" ry="10" width={48} height="48" />
					<rect x="60" y={135} rx="10" ry="10" width="40%" height="19" />
					<rect x="60" y={165} rx="7.5" ry="7.5" width="25%" height="15" />
					<rect x="60" y={190} rx="7.5" ry="7.5" width="25%" height="15" />
					<rect x="77.5%" y={180} rx="15" ry="15" width="22.5%" height="29" />
					{/* Third Left */}
					<rect x="0" y={225} rx="10" ry="10" width={48} height="48" />
					<rect x="60" y={225} rx="10" ry="10" width="40%" height="19" />
					<rect x="60" y={255} rx="7.5" ry="7.5" width="25%" height="15" />
					<rect x="60" y={280} rx="7.5" ry="7.5" width="25%" height="15" />
					<rect x="77.5%" y={270} rx="15" ry="15" width="22.5%" height="29" />
				</ContentLoader>
			</div>
			{renderInfo()}
		</>
	);
});

export const SkeletonViewBulk = withTime(({ time, backgroundColor }) => {
	const renderInfo = () => {
		if (time >= 15 && time <= 45)
			return (
				<p className={cx(styles.loadingText, 'mtLoading15Sec')}>
					Sebentar, lebih lama dari biasanya..
				</p>
			);
		if (time >= 45 && time < COUNTNUM)
			return (
				<>
					<p className={cx(styles.loadingText, 'mtLoading45Sec')}>
						Sabar ya, masih memuat....
					</p>
					<p className={styles.loadingTextDesc}>
						Terlalu lama ?
						<button
							className={cx(styles.btnLink, 'mtTryReload')}
							type="button"
							onClick={() => {
								window.location.reload();
							}}
						>
							Coba muat ulang
						</button>
					</p>
				</>
			);

		return <p className={styles.loadingText}>Sedang Memuat...</p>;
	};
	return (
		<>
			<div className={styles.loadingContainer}>
				<ContentLoader
					speed={0.5}
					height={240}
					width="100%"
					backgroundColor={backgroundColor}
				>
					<rect x="0" y="0" rx="10" ry="10" width="40%" height="24" />
					{/* First Item */}
					<rect x="0" y={45} rx="10" ry="10" width="60%" height="19" />
					<rect x="0" y={75} rx="7.5" ry="7.5" width="25%" height="15" />
					<rect x="30%" y={75} rx="7.5" ry="7.5" width="25%" height="15" />
					<rect x="80%" y={45} rx="10" ry="10" width="20%" height="19" />
					<rect x="75%" y={70} rx="15" ry="15" width="25%" height="29" />
					{/* Second side */}
					<rect x="0" y={115} rx="10" ry="10" width="60%" height="19" />
					<rect x="0" y={145} rx="7.5" ry="7.5" width="25%" height="15" />
					<rect x="30%" y={145} rx="7.5" ry="7.5" width="25%" height="15" />
					<rect x="80%" y={115} rx="10" ry="10" width="20%" height="19" />
					<rect x="75%" y={140} rx="15" ry="15" width="25%" height="29" />
					{/* Third Left */}
					<rect x="0" y={185} rx="10" ry="10" width="60%" height="19" />
					<rect x="0" y={215} rx="7.5" ry="7.5" width="25%" height="15" />
					<rect x="30%" y={215} rx="7.5" ry="7.5" width="25%" height="15" />
					<rect x="80%" y={185} rx="10" ry="10" width="20%" height="19" />
					<rect x="75%" y={210} rx="15" ry="15" width="25%" height="29" />
				</ContentLoader>
			</div>
			{renderInfo()}
		</>
	);
});
