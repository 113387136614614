import { useState, useRef, useEffect } from 'react';

export interface ObjectLiteral {
	[key: string]: any;
}

export interface SubmitForm {
	values: ObjectLiteral;
	errors: ObjectLiteral;
	isAnyErrors: boolean;
}

interface IProps {
	initialValue: any;
	onSubmit: ({ values, errors, isAnyErrors }: SubmitForm) => void;
	withCustomValidation?: (
		errValue: ObjectLiteral,
		formValue: ObjectLiteral,
	) => {
		errValue: ObjectLiteral;
		isAnyError: boolean;
	};
}

const useForm = ({ initialValue, onSubmit, withCustomValidation }: IProps) => {
	const [values, setValues] = useState(initialValue || {});
	const [touched, setTouched] = useState({});
	const [, setOnBlur] = useState<boolean>(false);
	const [errors, setErrors] = useState<ObjectLiteral>(initialValue || {});
	const [isAnyErrors, setIsAnyErrors] = useState<boolean>(false);
	const [isOnSubmit, setOnSubmitting] = useState<boolean>(false);
	const formRef = useRef(true);

	useEffect(() => {
		if (formRef.current) {
			setValues(initialValue);
			setTouched({});
			setOnBlur(false);
			setErrors(initialValue);
			setOnSubmitting(false);
		}

		formRef.current = false;
	}, [initialValue]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { target } = e;
		const { name, value, validity } = target;
		if (validity.valid) {
			e.persist();
			const valuesDt = { ...values, [name]: value };
			setValues(valuesDt);
			let currentError = { ...errors, [name]: '' };
			let isAnyError = false;

			if (typeof withCustomValidation !== 'undefined') {
				const customValidation = withCustomValidation(currentError, valuesDt);
				currentError = customValidation.errValue;
				isAnyError = customValidation.isAnyError;
			}

			setErrors({ ...currentError });
			setIsAnyErrors(isAnyError);
		} else {
			setValues({ ...values, [name]: value });
			setErrors({ [name]: 'invalid-format' });
		}
	};

	const handleChangeInject = (key: string, value: string) => {
		setValues({ ...values, [key]: value });
	};

	const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = e;
		const { name } = target;
		setTouched({ ...touched, [name]: true });
		setErrors({ ...errors });
	};

	const handleSubmit = (e: any) => {
		if (e) e.preventDefault();
		let currentError = errors;
		let currentIsAnyErrors = false;
		for (const key in values) {
			if (values[key] === '') {
				currentError = { ...currentError, [key]: `required` };
				currentIsAnyErrors = true;
			}
		}

		if (typeof withCustomValidation !== 'undefined') {
			const customValidation = withCustomValidation(currentError, values);
			currentError = customValidation.errValue;
			currentIsAnyErrors = customValidation.isAnyError;
		}

		setErrors({ ...currentError });
		setIsAnyErrors(currentIsAnyErrors);
		setOnSubmitting(true);
		onSubmit({ values, errors: currentError, isAnyErrors: currentIsAnyErrors });
	};

	const handleSetInitialValue = (initialValue: any) => {
		setValues(initialValue || {});
	};

	return {
		values,
		isAnyErrors,
		errors,
		touched,
		handleChange,
		handleChangeInject,
		handleBlur,
		handleSubmit,
		handleSetInitialValue,
		isOnSubmit,
	};
};

export default useForm;
