import React, { useState } from 'react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import Product from '../Product';
import CheckoutButton from '../CheckoutButton';
import Backdrop from '../Backdrop';
import RedirectPopup from '../RedirectPopup';
import { ReactComponent as SendIcon } from '../../assets/icons/send.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

import { ICart, IProduct } from '../../interface/products';
import { ICheckoutForm } from '../../interface/checkoutForm';

import { formatRp } from '../../utils/currency';
import styles from './index.module.css';
import SimpleProduct from '../SimpleProduct';
// import ProductPopup from '../ProductPopup';

interface IProps {
	cartItems: IProduct[];
	cart: ICart;
	setCart: (cart: ICart) => void;
	show: boolean;
	hidePopup: () => void;
	bulk?: boolean;
	token?: string;
	isCityBounded: string;
	storeId: string;
	checkoutForm?: ICheckoutForm[];
	totalPrice: number;
	totalPriceBeforeDiscount: number;
}

const CheckoutPopup: React.FC<IProps> = ({
	cartItems,
	cart,
	setCart,
	show,
	hidePopup,
	bulk = false,
	token,
	isCityBounded,
	storeId,
	checkoutForm,
	totalPrice,
	totalPriceBeforeDiscount,
}) => {
	const [showLoader, setShowLoader] = useState(false);
	const [notes, setNotes] = useState('');
	const displayLoader = () => setShowLoader(true);

	const [, setClickedProduct] = useState<IProduct>({
		id: '',
		name: '',
		excerpt: '',
		price: 0,
		availability: false,
		stock: 0,
	});

	// const discount = cart.totalPriceBeforeDiscount - cart.totalPrice;

	const discount = totalPriceBeforeDiscount - totalPrice;

	const renderTotalPrice = () => {
		if (discount > 0) {
			return (
				<>
					<span>
						<span className={styles.totalPriceSliced}>
							{formatRp(totalPriceBeforeDiscount)}
						</span>
						<span className={styles.totalPriceNormal}>{formatRp(totalPrice)}</span>
					</span>
				</>
			);
		}

		return (
			<>
				<span>{formatRp(totalPrice)}</span>
			</>
		);
	};
	return (
		<>
			<Backdrop show={show} onClick={hidePopup} />
			<CSSTransition
				in={show}
				timeout={500}
				classNames={{
					enter: styles['popup-enter'],
					enterActive: styles['popup-enter-active'],
					exit: styles['popup-exit'],
					exitActive: styles['popup-exit-active'],
				}}
				unmountOnExit
			>
				<div className={cx(styles.wrapper)}>
					<div className={styles.container}>
						<div className={styles.titleContainer}>
							<h2 className={styles.title}>Konfirmasi Pesanan</h2>
							<CloseIcon onClick={hidePopup} className={styles.close} />
						</div>
						<hr className={styles.divider} />
						{cartItems.map(item =>
							bulk ? (
								<SimpleProduct
									key={item.id}
									product={item}
									cart={cart}
									setCart={setCart}
								/>
							) : (
								<Product
									key={item.id}
									product={item}
									cart={cart}
									setCart={setCart}
									popup={false}
									onClickProduct={(product: any) => {
										setClickedProduct(product);
									}}
								/>
							),
						)}

						<button
							className={cx(styles.addButton, 'mtAddItemPopUp')}
							onClick={hidePopup}
						>
							+ Tambah Barang
						</button>

						<hr className={styles.hrBorder} />
						<h3 className={styles.totalPrice}>
							<span>Total Harga :</span>
							{renderTotalPrice()}
						</h3>
						<hr className={styles.hrBorder} />
						{discount > 0 && (
							<>
								<div className={styles.txtDiscount}>
									Selamat kamu hemat {formatRp(discount)}
								</div>
							</>
						)}
						<h3 className={styles.totalPrice}>Catatan untuk penjual</h3>

						<textarea
							id="addressInput"
							onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
								setNotes(e.target.value);
							}}
							className={styles.notesTxt}
							name="address"
							rows={4}
							placeholder="Tulis keterangan tambahan kamu disini...."
						>
							{notes}
						</textarea>
						<div className={styles.wrapperBtnCheckout}>
							<CheckoutButton
								onClick={displayLoader}
								icon={SendIcon}
								withClassName="mtSendCheckout"
							>
								Kirim Checkout Saya
							</CheckoutButton>
						</div>
					</div>
				</div>
			</CSSTransition>
			<RedirectPopup
				show={showLoader}
				// show={true}
				cart={cart}
				onHideShowLoader={() => {
					setShowLoader(false);
				}}
				notes={notes}
				token={token}
				isCityBounded={isCityBounded}
				storeId={storeId}
				checkoutForm={checkoutForm}
			/>
		</>
	);

	// if (!show) return null;
	// return (
	//   <>
	//     <Backdrop show onClick={hidePopup} />
	//     <div className={cx(styles.wrapper)}>
	//       <div className={styles.container}>
	//         <div className={styles.titleContainer}>
	//           <h2 className={styles.title}>Konfirmasi Pesanan</h2>
	//           <CloseIcon onClick={hidePopup} className={styles.close} />
	//         </div>
	//         <hr className={styles.divider} />
	//         {cartItems.map((item) =>
	//           bulk ? (
	//             <SimpleProduct
	//               key={item.id}
	//               product={item}
	//               cart={cart}
	//               setCart={setCart}
	//             />
	//           ) : (
	//             <Product
	//               key={item.id}
	//               product={item}
	//               cart={cart}
	//               setCart={setCart}
	//               popup={false}
	//             />
	//           )
	//         )}
	//         <button className={styles.addButton} onClick={hidePopup}>
	//           + Tambah Barang
	//         </button>
	//         <h3 className={styles.totalPrice}>
	//           Total : {formatRp(cart.totalPrice)}
	//         </h3>
	//         <CheckoutButton onClick={displayLoader} icon={SendIcon}>
	//           Kirim Checkout Saya
	//         </CheckoutButton>
	//       </div>
	//     </div>
	//     <RedirectPopup show={showLoader} cart={cart} />
	//   </>
	// );
};

export default CheckoutPopup;
