import axios from 'axios';

const config = {
	baseURL: process.env.REACT_APP_API_BASE_URL,
};

export const TIMEOUT = 75000;
export const TIMEOUTCHECKOUT = 45000;

export const AxiosCreateTO = (customTimeout?: number) => {
	return axios.create({
		...config,
		timeout: customTimeout || TIMEOUT,
	});
};

export default axios.create(config);
