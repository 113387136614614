/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import SearchBar from '../../../components/SearchBar';
import SearchHint from '../../../components/SearchHint';
import ProductGroup from '../../../components/ProductGroup';
import CartSummary from '../../../components/CartSummary';
import { SkeletonViewBasic } from '../../../components/SkeletonView';
import StoreHeader from '../../../components/StoreHeader';
import NotFound from '../../../components/ErrorPage/NotFound';
import LoadFail from '../../../components/ErrorPage/LoadFail';
import Expire from '../../../components/ErrorPage/Expire';
import EmptyProduct from '../../../components/ErrorPage/EmptyProduct';
import ReqTimeout from '../../../components/ErrorPage/Timeout';
import { IProducts, ICart, ISearchProduct } from '../../../interface/products';
import { ICheckoutForm } from '../../../interface/checkoutForm';
import { ISocialLink } from '../../../interface/socialLink';
import {
	getKinoProduct,
	getKinoCategory,
	generateProductKinoApiUrl,
	generateCategoryKinoApiUrl,
} from '../../../api/product';
import { setCartItem, getCartItem } from '../../../utils/localStorage';
import { replaceAll } from '../../../utils/text';
import { useQuery } from '../../../hooks/useQuery';
import SetTheme, { ThemeProps, defaultTheme } from '../../../utils/setTheme';
import { LightTheme, DarkTheme } from '../../../utils/setTheme/default';

import styles from './index.module.css';
import { setAmplitudeData } from '../../../utils/amplitude';

import converterToChatNShop from './kinoConv';

const Store: React.FC<RouteChildrenProps<{ storeId: string }>> = () => {
	// const { storeId } = useParams<any>();
	const storeId = 'kino-store';

	const token = useQuery().get('token') || '';

	const defaultSearch = useQuery().get('search') || '';

	const [storeName, setStoreName] = useState('');
	const [storeDesc, setStoreDesc] = useState('');

	const [storeSlogan, setStoreSlogan] = useState('');

	const [storeLogo, setStoreLogo] = useState('');
	const [storeLogoBG, setStoreLogoBG] = useState('');

	const [error, setError] = useState('');
	const [fullErrorResponse, setFullErrorResponse] = useState<any>({});
	const [search, setSearch] = useState('');
	const [searchSuggestion, setSearchSuggestion] = useState<IProducts[]>([]);
	const [products, setProducts] = useState<IProducts[]>([]);
	const [searchedProducts, setSearchedProducts] = useState<ISearchProduct>({
		query: '',
		total: 0,
		products: [],
	});
	const [categories, setCategories] = useState<string[]>([]);
	const [checkoutForm, setCheckoutForm] = useState<ICheckoutForm[]>([]);
	const [storeAddress, setStoreAddress] = useState<string>('');
	const [storeCity, setStoreCity] = useState<string>('');
	const [socialLink, setSocialLink] = useState<ISocialLink[]>([]);
	const oldCart = getCartItem(storeId);
	let parseOldCart = oldCart && JSON.parse(oldCart);
	if (parseOldCart) {
		parseOldCart.token = token;
	}

	const [cart, setCart] = useState<ICart>(
		{
			storeId,
			token,
			totalItems: 0,
			totalPrice: 0,
			totalPriceBeforeDiscount: 0,
			items: {},
			...parseOldCart,
		} || {
			storeId,
			token,
			totalItems: 0,
			totalPrice: 0,
			totalPriceBeforeDiscount: 0,
			items: {},
		},
	);
	const [isLoading, setIsLoading] = useState(false);
	const isFakeLoading = false;
	const [isCityBounded, setIsCityBounded] = useState<string>('');
	const [themeColor, setThemeColor] = useState<ThemeProps>(defaultTheme);

	const onSetTheme = (data: any) => {
		const { theme } = data;
		if (theme && theme.preset) {
			switch (theme.preset) {
				case 'dark':
					setThemeColor(DarkTheme);
					break;
				default:
					setThemeColor(LightTheme);
			}
		} else {
			const dataTheme: ThemeProps = {
				...LightTheme,
				...theme,
			};

			setThemeColor(dataTheme);
		}
	};

	useEffect(() => {
		const getProducts = async () => {
			setIsLoading(true);
			try {
				if ('caches' in window) {
					caches.match(generateProductKinoApiUrl()).then(cacheProduct => {
						if (cacheProduct) {
							cacheProduct.json().then(jsonProduct => {
								caches.match(generateCategoryKinoApiUrl()).then(cacheCategory => {
									if (cacheCategory) {
										cacheCategory.json().then(jsonCategory => {
											if (
												jsonProduct.status === 200 &&
												jsonCategory.status === 200
											) {
												const json: any = converterToChatNShop(
													jsonProduct,
													jsonCategory,
												);

												setAmplitudeData('Visit Store', {
													store_name: json.store_name,
													store_id: storeId,
												});
												setStoreName(json.store_name);
												setStoreDesc(json.store_desc);
												setStoreLogo(json.store_logo);
												setStoreSlogan(json.store_slogan);
												setStoreLogoBG(json.store_logo_bg);
												setProducts(json.results);
												setCheckoutForm(json.checkout_form);
												setSocialLink(json.store_social_link);
												setStoreCity(json.store_area);
												setStoreAddress(json.store_address);
												setSearchedProducts({
													query: '',
													total: 0,
													products: json.results,
												});
												setIsLoading(false);
												onSetTheme(json);
											}
										});
									}
								});
							});
						}
					});
				}

				// const { data } = await getProductAPI_WTO(storeId, token);

				const dataKinoProduct: any = await getKinoProduct(token);
				const dataKinoCategory: any = await getKinoCategory(token);

				if (dataKinoProduct.status === 200 && dataKinoCategory.status === 200) {
					const data: any = converterToChatNShop(
						dataKinoProduct.data,
						dataKinoCategory.data,
					);

					setAmplitudeData('Visit Store', {
						store_name: storeId,
						store_id: storeId,
					});
					setStoreName(data.store_name);
					setStoreDesc(data.store_desc);
					setStoreSlogan(data.store_slogan);
					setStoreLogo(data.store_logo);
					setStoreLogoBG(data.store_logo_bg);
					setProducts(data.results);
					setCheckoutForm(data.checkout_form);
					setSocialLink(data.store_social_link);
					setStoreCity(data.store_area);
					setStoreAddress(data.store_address);
					setSearchedProducts({
						query: '',
						total: 0,
						products: data.results,
					});
					setIsLoading(false);
					setIsCityBounded(
						data.must_choose_subdistrict &&
							data.is_city_bounded &&
							data.is_city_bounded !== ''
							? data.is_city_bounded
							: '',
					);
					onSetTheme(data);
					const oldCart2 = getCartItem(storeId);
					const parseOldCart2 = oldCart2 && JSON.parse(oldCart2);
					if (parseOldCart2) {
						let totalCart = 0;
						let totalPriceBeforeDiscount = 0;

						Object.keys(parseOldCart2.items).forEach(elKey => {
							let checkId = false;
							data.results.forEach((el: any) => {
								el.items.forEach((el2: any) => {
									if (el2.id === elKey) {
										checkId = true;
									}
								});
							});

							if (!checkId) {
								delete parseOldCart2.items[elKey];
							}
						});

						data.results.forEach((el: any) => {
							el.items.forEach((el2: any) => {
								Object.keys(parseOldCart2.items).forEach(elKey => {
									if (el2.id === elKey) {
										if (
											!el2.availability ||
											parseOldCart2.items[elKey] > el2.stock
										) {
											delete parseOldCart2.items[elKey];
										} else {
											totalCart += el2.price * parseOldCart2.items[elKey];
											const price_before_discount = !el2.price_before_discount
												? el2.price
												: el2.price_before_discount;
											totalPriceBeforeDiscount +=
												price_before_discount * parseOldCart2.items[elKey];
										}
									}
								});
							});
						});

						const countItems = () => {
							let count = 0;
							Object.keys(parseOldCart2.items).forEach(val => {
								count += parseOldCart2.items[val];
							});

							return count;
						};

						parseOldCart2.totalItems = countItems();
						parseOldCart2.totalPrice = totalCart;
						parseOldCart2.totalPriceBeforeDiscount = totalPriceBeforeDiscount;
						parseOldCart2.token = token;

						setCart(parseOldCart2);
						setCartItem(storeId, JSON.stringify(parseOldCart2));
					}
				}
			} catch (e) {
				console.log('---e', e);
				if (e.code === 'ECONNABORTED') {
					setFullErrorResponse(JSON.parse(JSON.stringify(e)));
					setError(e.code);
				} else if (e.response && e.response.data) {
					setFullErrorResponse(e.response.data);
					setError(e.response.data.name);
				} else {
					setFullErrorResponse(e);
					setError(e);
				}
				setIsLoading(false);
			}
		};

		getProducts();
	}, [storeId, token]);

	useEffect(() => {
		if (searchedProducts.products && searchedProducts.products.length > 0)
			setCategories(searchedProducts.products.map(product => product.category));
	}, [searchedProducts]);

	const setCartWithCache = (data: any) => {
		setCart(data);
		setCartItem(storeId, JSON.stringify(data));
	};

	const searchQuery = (s?: string) => {
		const matchedProducts: IProducts[] = [];
		let totalProducts = 0;
		const findStr = s || search;
		for (const product of products) {
			const items = product.items.filter(item =>
				item.name.toLowerCase().includes(findStr.toLowerCase()),
			);

			totalProducts += items.length;
			if (items.length > 0) matchedProducts.push({ ...product, items });
		}
		return {
			totalProducts,
			matchedProducts,
		};
	};

	const actionSearch = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const { totalProducts, matchedProducts } = searchQuery();

		setSearchedProducts({
			query: search,
			total: totalProducts,
			products: matchedProducts,
		});
	};

	const actionSearchStr = (sr: string) => {
		const { totalProducts, matchedProducts } = searchQuery(sr);
		setSearch(sr);
		setSearchedProducts({
			query: sr,
			total: totalProducts,
			products: matchedProducts,
		});
	};

	const clearSearch = () => {
		setSearch('');
		setSearchedProducts({
			query: '',
			total: 0,
			products: products,
		});
	};
	const actionClearSearch = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		clearSearch();
	};

	useEffect(() => {
		if (!isLoading && defaultSearch !== '') {
			actionSearchStr(replaceAll(defaultSearch, '-', ' '));
		}
	}, [isLoading, defaultSearch]);

	const renderPage = () => {
		const loadFail = () => {
			const { store_name, store_whatsapp_number, name } = fullErrorResponse;
			return (
				<LoadFail
					store_name={store_name || ''}
					store_whatsapp_number={store_whatsapp_number || ''}
					errorName={name || ''}
				/>
			);
		};
		const loadWithHeader = () => {
			return (
				<>
					<SearchBar
						query={searchedProducts.query}
						search={search}
						setSearch={(search: string) => {
							if (search === '') {
								setSearchSuggestion([]);
							} else {
								const { matchedProducts } = searchQuery(search);
								setSearchSuggestion(matchedProducts);
							}

							setSearch(search);
						}}
						actionSearch={actionSearch}
						categories={categories}
						actionClearSearch={actionClearSearch}
						clearSearch={clearSearch}
						totalSearch={searchedProducts.total}
						actionSearchStr={actionSearchStr}
						searchSuggestion={searchSuggestion}
						themeType={themeColor.themeType}
					/>
					<StoreHeader
						query={searchedProducts.query}
						total={searchedProducts.total}
						storeName={storeName}
						error={error}
						token={token}
						storeDesc={storeDesc}
						storeLogo={storeLogo}
						storeLogoBG={storeLogoBG}
						socialLink={socialLink}
						storeAddress={storeAddress}
						storeCity={storeCity}
						inCart={cart.totalItems > 0}
					/>

					<ReqTimeout />
				</>
			);
		};
		if (error) {
			if (error === 'STORE_EXPIRED_ERR') {
				return <Expire />;
			} else if (error === 'STORE_NO_GSHEET_INTEGRATED_ERR') {
				return loadFail();
			} else if (error === 'STORE_NOT_FOUND_ERR') {
				return <NotFound />;
			} else if (error === 'ECONNABORTED') {
				return loadWithHeader();
			} else if (error === 'TokenExpiredError') {
				return <NotFound msg={error} />;
			} else {
				return loadFail();
			}
		}

		const renderContent = () => {
			if (isLoading || isFakeLoading) {
				return <SkeletonViewBasic themeType={themeColor.themeType} />;
			}
			if (searchedProducts.query !== '' && searchedProducts.products.length === 0) {
				return (
					<>
						<EmptyProduct query={searchedProducts.query} />
						<SearchHint clearSearch={clearSearch} isClearSearch />
					</>
				);
			}

			return (
				<>
					<ProductGroup
						products={searchedProducts.products}
						cart={cart}
						setCart={e => {
							setAmplitudeData('Click Add to Cart', e);
							setCartWithCache(e);
						}}
					/>
					{searchedProducts.query !== '' ? (
						<SearchHint clearSearch={clearSearch} isClearSearch />
					) : (
						<SearchHint clearSearch={clearSearch} />
					)}
					<CartSummary
						cart={cart}
						setCart={setCartWithCache}
						products={products}
						token={token}
						isCityBounded={isCityBounded}
						storeId={storeId}
						checkoutForm={checkoutForm}
					/>
				</>
			);
		};

		return (
			<>
				<SearchBar
					query={searchedProducts.query}
					search={search}
					setSearch={(search: string) => {
						if (search === '') {
							setSearchSuggestion([]);
						} else {
							const { matchedProducts } = searchQuery(search);
							setSearchSuggestion(matchedProducts);
						}

						setSearch(search);
					}}
					actionSearch={actionSearch}
					categories={categories}
					actionClearSearch={actionClearSearch}
					clearSearch={clearSearch}
					totalSearch={searchedProducts.total}
					searchSuggestion={searchSuggestion}
					actionSearchStr={actionSearchStr}
					themeType={themeColor.themeType}
				/>
				<StoreHeader
					query={searchedProducts.query}
					total={searchedProducts.total}
					storeName={storeName}
					error={error}
					token={token}
					storeDesc={storeDesc}
					storeLogo={storeLogo}
					storeLogoBG={storeLogoBG}
					themeType={themeColor.themeType}
					socialLink={socialLink}
					storeAddress={storeAddress}
					storeCity={storeCity}
					inCart={cart.totalItems > 0}
				/>
				{renderContent()}
			</>
		);
	};

	// if (!storeId || !token) return <p className={styles.error}>Not valid URL</p>;
	if (!storeId) return <p className={styles.error}>Not valid URL</p>;

	let seoTitle = storeName;
	if (storeSlogan) {
		seoTitle += ' | ' + storeSlogan;
	}

	let titleSeo = '';

	if (seoTitle) {
		titleSeo = seoTitle;
	} else if (storeName) {
		titleSeo = `Belanja mudah di ${storeName} dengan ChatNShop`;
	} else {
		titleSeo = 'Jualan Mudah Tanpa Aplikasi Tambahan | ChatNShop';
	}

	return (
		<SetTheme theme={themeColor}>
			<div className={styles.wrapperContainer}>
				<div className={styles.container}>
					<Helmet>
						<title>{titleSeo}</title>
						<meta
							name="description"
							content={
								storeDesc ||
								`Belanja mudah di ${storeName} dengan ChatNShop` ||
								'ChatNShop menyediakan cara mudah kelola jualan tanpa aplikasi tambahan. Sedia web praktis, order lewat Whatsapp, dan rekap spreadsheet tanpa ribet, gak perlu mikir lagi.'
							}
						/>
					</Helmet>
					{renderPage()}
				</div>
			</div>
		</SetTheme>
	);
};

export default Store;
