import styled from 'styled-components';

export const SuggestWrapper = styled.div`
	position: relative;
	.react-autosuggest__suggestions-container--open {
		background-color: var(--chatnshop-wrapperBg);
		position: absolute;
		width: 430px;
		padding: 0 1rem;
		max-height: 200px;
		overflow-x: auto;
		border: 1px solid var(--chatnshop-wrapperBg);
		box-sizing: border-box;

		@media (max-width: 480px) {
			width: 90%;
		}
		ul {
			list-style: none;
			padding: 0 1rem;
			li {
				margin-top: 0.5rem;
				cursor: pointer;
				span {
					color: var(--chatnshop-primmaryTxtColor);
				}
			}
		}
	}

	&.error {
		input {
			border: 1px solid red !important;
		}
	}
`;
