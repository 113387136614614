import React, { useEffect } from 'react';
import GlobalThemeReplacer from './injectTheme';
import tohsl from '../color.js';
import { LightTheme } from './default';

export interface ThemeProps {
	navBg: string;
	searchBorderColor: string;
	catBtnBg: string;
	catBtnColor: string;
	wrapperBg: string;
	mainBg: string;
	btnBg: string;
	btnColor: string;
	highlightTxtColor: string;
	formBg: string;
	formBorderBg: string;
	primmaryTxtColor: string;
	themeType: string; // dark or light
	redTextColor: string;
}

interface IProps {
	theme: ThemeProps;
	children: React.ReactNode;
}

const documentInject = (key: string, value: string | null) => {
	document.documentElement.style.setProperty(key, value ? value : '#fff');
};

const convertToHslActive = (hex: string) => {
	const { h, s, l } = tohsl(hex);

	return {
		result: `hsl(${h - 30},${s + 20}%,${l + 10}%)`,
		txt: l >= 60 ? 'hsl(0,0%,0%)' : ' hsl(0, 0%, 100%)',
	};
};

const convertToHslHover = (hex: string) => {
	const { h, s, l } = tohsl(hex);

	return {
		result: `hsl(${h + 10},${s + 10}%,${l - 20}%)`,
		txt: l >= 60 ? 'hsl(0,0%,0%)' : ' hsl(0, 0%, 100%)',
	};
};

const convertToHslBoxShadow = (hex: string) => {
	const { h, s, l } = tohsl(hex);

	return {
		result: `hsla(${h},${s + 25}%,${l - 35}%,0.15)`,
		txt: l >= 60 ? 'hsl(0,0%,0%)' : ' hsl(0, 0%, 100%)',
	};
};

const SetTheme: React.FC<IProps> = ({ theme, children }) => {
	useEffect(() => {
		const flag = '--chatnshop-';

		const primaryHover = convertToHslHover(theme.navBg);
		const primaryActive = convertToHslActive(theme.navBg);

		const secondaryHover = convertToHslHover(theme.btnBg);
		const secondaryActive = convertToHslActive(theme.btnBg);
		const secondaryBoxShadow = convertToHslBoxShadow(theme.btnBg);

		documentInject(`${flag}navBg`, theme.navBg);
		documentInject(`${flag}catBtnBg`, theme.catBtnBg);

		documentInject(`${flag}catBtnBg-box-shadow`, secondaryBoxShadow.result);
		documentInject(`${flag}catBtnBg-hover`, primaryHover.result);
		documentInject(`${flag}catBtnBg-hover-txt`, primaryHover.txt);

		documentInject(`${flag}catBtnBg-active`, primaryActive.result);
		documentInject(`${flag}catBtnBg-active-txt`, primaryActive.txt);

		documentInject(`${flag}btnBg`, theme.btnBg);
		documentInject(`${flag}btnColor`, theme.btnColor);

		documentInject(`${flag}btnBg-hover`, secondaryHover.result);
		documentInject(`${flag}btnBg-hover-txt`, secondaryHover.txt);

		documentInject(`${flag}btnBg-active`, secondaryActive.result);
		documentInject(`${flag}btnBg-active-txt`, secondaryActive.txt);

		documentInject(`${flag}searchBorderColor`, theme.searchBorderColor);

		documentInject(`${flag}catBtnColor`, theme.catBtnColor);
		documentInject(`${flag}wrapperBg`, theme.wrapperBg);
		documentInject(`${flag}mainBg`, theme.mainBg);
		documentInject(`${flag}highlightTxtColor`, theme.highlightTxtColor);
		documentInject(`${flag}formBg`, theme.formBg);
		documentInject(`${flag}formBorderBg`, theme.formBorderBg);
		documentInject(`${flag}primmaryTxtColor`, theme.primmaryTxtColor);
		documentInject(`${flag}themeType`, theme.themeType);
		documentInject(`${flag}redTextColor`, theme.redTextColor);

		if (theme.themeType === 'dark') {
			documentInject(`${flag}defaultThemeColor`, '#ffffff');
			documentInject(`${flag}checkIcon`, '#ffffff');
		} else {
			documentInject(`${flag}defaultThemeColor`, '#000000');
			documentInject(`${flag}checkIcon`, '#066FC2');
		}
	}, [
		theme.navBg,
		theme.searchBorderColor,
		theme.catBtnBg,
		theme.catBtnColor,
		theme.wrapperBg,
		theme.mainBg,
		theme.btnBg,
		theme.btnColor,
		theme.highlightTxtColor,
		theme.formBg,
		theme.formBorderBg,
		theme.primmaryTxtColor,
		theme.themeType,
		theme.redTextColor,
	]);

	return <GlobalThemeReplacer>{children}</GlobalThemeReplacer>;
};

export const defaultTheme: ThemeProps = LightTheme;

export const onChangeDummyTheme: ThemeProps = {
	navBg: '#323232',
	searchBorderColor: '#0F0F0F',
	catBtnBg: '#000000',
	catBtnColor: '#EC9E08',
	wrapperBg: '#0F0F0F',
	mainBg: '#202020',
	btnBg: '#EC9E08',
	btnColor: '#202020',
	highlightTxtColor: '#FFDD76',
	formBg: '#333333',
	formBorderBg: '#000000',
	primmaryTxtColor: '#EFEFEF',
	themeType: 'dark', // dark or light
	redTextColor: '#EB5757',
};

export default SetTheme;
