import React from 'react';
import cx from 'classnames';
import styles from './index.module.css';
import { ReactComponent as ExpireSVG } from '../../../assets/icons/storeClose.svg';

const Expire: React.FC = () => (
	<div className={cx(styles.wrapper)} id="mtErrorPageExpire">
		<div className={styles.container}>
			<ExpireSVG />
			<div className={styles.txtTitle}>Yah... Toko ini sudah tidak aktif</div>
			<div className={styles.txtDesc}>
				Yuk kasih tau penjual untuk mengaktifkan tokonya lagi!
			</div>
			<div className={styles.txtFooter}>Kode Error : services_expired </div>
		</div>
	</div>
);

export default Expire;
