import React, { useEffect, useState, useCallback } from 'react';
import { SpinnerCircular } from 'spinners-react';
import cx from 'classnames';
import dayjs from 'dayjs';

import { ReactComponent as RedirectIcon } from '../../assets/icons/redirect.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/done.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { checkoutAPI } from '../../api/product';
import { ICart } from '../../interface/products';
import FormInfo from './Form/FormInfo';
import FormCheckout from './Form/FormCheckout';
import styles from './index.module.css';
import { getItem, removeCartItem, constanstMessage } from '../../utils/localStorage';
import { ICheckoutForm } from '../../interface/checkoutForm';
import { setAmplitudeData, setAmplitudeUserId } from '../../utils/amplitude';

interface IProps {
	show: boolean;
	cart: ICart;
	onHideShowLoader: () => void;
	notes: string;
	token?: string;
	isCityBounded: string;
	storeId: string;
	checkoutForm?: ICheckoutForm[];
}

const RedirectPopup: React.FC<IProps> = ({
	show,
	cart,
	onHideShowLoader,
	notes,
	token,
	isCityBounded,
	storeId,
	checkoutForm,
}) => {
	const [success, setSuccess] = useState<null | boolean>(null);

	const [isTimeOut, setIsTimeOut] = useState<null | boolean>(null);
	const [errorDescription, setErrorDescription] = useState(
		'Tidak biasanya kami mengalami ini. Jangan khawatir, kamu bisa coba lagi lain waktu ya.',
	);
	const [reportUrl, setReportUrl] = useState(
		`https://api.whatsapp.com/send?phone=6289522028530&text=Halo,%20saya%20ingin%20melaporkan%20adanya%20kesalahan%20saat%20melakukan%20checkout%20di%20aplikasi%20${
			cart.storeId
		}%20pada%20waktu%20${dayjs().format('DD-MM-YYYY HH:mm:ss')}%20WIB`,
	);
	const [redirectURL, setRedirectURL] = useState('');
	const [renderForm, setRenderForm] = useState<boolean>(cart.token === '');
	const [infoCheckoutForm, setInfoCheckoutForm] = useState<any>();

	const [shouldRenderCheckoutForm, setShouldRenderCheckoutForm] = useState<boolean>(
		(checkoutForm && checkoutForm.length > 0) || false,
	);

	const checkoutProduct = useCallback(
		async (withFormValue?: any) => {
			const adjustedCart = Object.keys(cart.items).map(id => ({
				'product-code': id,
				quantity: cart.items[id],
			}));

			const cartWithToken = { token: cart.token, cart: adjustedCart };

			try {
				let result;
				if (cart.token === '') {
					if (!withFormValue) {
						const dtStrSubDistrict =
							getItem(constanstMessage.SHOP_STORE_SUB_DISTRICT_SELECT_OPT) || '';
						const subDistrictParse = dtStrSubDistrict;
						withFormValue = {
							name: getItem(constanstMessage.SHOP_STORE_NAME) || '',
							mobile: getItem(constanstMessage.SHOP_STORE_PHONE_NUMBER) || '',
							address: getItem(constanstMessage.SHOP_STORE_ADDRESS) || '',
							subDistrict:
								subDistrictParse !== '' && isCityBounded !== '' && subDistrictParse,
							notes: notes,
						};
					}

					if (!isCityBounded) {
						delete withFormValue['subDistrict'];
					}
					result = await checkoutAPI(cart.storeId, cartWithToken, {
						...withFormValue,
						notes: notes,
					});
				} else {
					result = await checkoutAPI(cart.storeId, cartWithToken, {
						notes: notes,
					});
				}
				if (result && result.data.status === 'success') {
					setSuccess(true);
					removeCartItem(cart.storeId);

					const url = result.data.result.redirect;
					setRedirectURL(url);
					setTimeout(() => window.location.replace(url), 1000);
				}
			} catch (error) {
				if (error.code === 'ECONNABORTED') {
					setIsTimeOut(true);
				} else {
					setSuccess(false);

					if (error.response?.data?.message)
						setErrorDescription(error.response.data.message);
					if (error.response?.data?.report_url)
						setReportUrl(error.response.data.report_url);
				}
			}
		},
		[cart, notes, isCityBounded],
	);

	useEffect(() => {
		if (show && cart.token !== '') checkoutProduct();
	}, [show, cart, checkoutProduct, renderForm]);

	useEffect(() => {
		setShouldRenderCheckoutForm((checkoutForm && checkoutForm.length > 0) || false);
	}, [checkoutForm]);

	const renderSpinner = () => {
		if (success === null) {
			return (
				<SpinnerCircular
					color="#fff"
					size={200}
					speed={99}
					thickness={139}
					className={styles.doneIcon}
				/>
			);
		}
		if (success === true)
			return <CheckIcon className={cx(styles.doneIcon, styles.checkIcon)} />;
		if (success === false)
			return <CrossIcon className={cx(styles.doneIcon, styles.crossIcon)} />;
	};

	const renderIcon = () => {
		if (success === true) return <RedirectIcon className={styles.bodyIcon} />;
		if (success === false) return <InfoIcon className={styles.bodyIcon} />;
	};

	const renderText = () => {
		if (success === null) {
			return (
				<div className="mtSprinnerProcess">
					<h1 className={styles.title}>Pesanan Diproses</h1>
					<p className={styles.description}>
						Checkout daftar belanjaan kamu sedang dialihkan dan dikirim ke WhatsApp kami
					</p>
					<p className={styles.description}>Mohon tunggu sebentar...</p>
				</div>
			);
		}
		if (success === false) {
			return (
				<div>
					<h1 className={styles.title}>Hmm Ada yang salah...</h1>
					<p className={styles.description}>{errorDescription}</p>
					<p className={styles.errorMessage}>
						<a
							href={reportUrl}
							className={cx(styles.button, 'mtReportProblem')}
							target="_blank"
							rel="noopener noreferrer"
						>
							Laporkan kesalahan ini
						</a>
					</p>
				</div>
			);
		}
		if (success === true) {
			return (
				<div id={`mtRedirectSuccess_${storeId}`}>
					<h1 className={styles.title}>Berhasil</h1>
					<p className={styles.description}>Sedang mengalihkan Anda...</p>
					<button
						className={cx(styles.button, 'mtPressHereSuccess')}
						onClick={() => window.location.replace(redirectURL)}
					>
						Tekan disini jika masih belum teralihkan
					</button>
				</div>
			);
		}
	};

	const renderCondition = () => {
		if (isTimeOut) {
			return (
				<>
					<div className={cx(styles.bodyColumn, 'mtSpinnerPage')}>
						<div className={cx(styles.bodyColumnItems, 'mtSpinnerPage')}>
							<InfoIcon className={styles.bodyIcon} />
							<div>
								<h1 className={styles.title}>Waduh kelamaan?</h1>
								<p className={styles.description}>
									Jangan khawatir, coba muat ulang lagi dan pastikan internet kamu
									stabil. Jika masih, coba hubungkan ulang internet kamu atau
									gunakan koneksi lain.
								</p>
							</div>
						</div>
						<button
							className={cx(styles.tryButton, 'mtSpinnerPageBtnTryAgain')}
							onClick={() => {
								setIsTimeOut(false);
								checkoutProduct();
							}}
						>
							Muat Ulang
						</button>
					</div>
				</>
			);
		}
		return (
			<>
				{renderSpinner()}
				<div className={cx(styles.body, 'mtSpinnerPage')}>
					<div className={styles.textWrapper}>
						{renderIcon()}
						{renderText()}
					</div>

					{success === false && (
						<button
							className={cx(styles.tryButton, 'mtSpinnerPageBtnTryAgain')}
							onClick={() => {
								setSuccess(null);
								checkoutProduct();
							}}
						>
							Coba Lagi
						</button>
					)}
				</div>
			</>
		);
	};

	const renderFormCondition = () => {
		if (renderForm && !token) {
			return (
				<FormInfo
					cart={cart}
					onClick={(
						name: string,
						mobile: string,
						address: string,
						subDistrict: string,
					) => {
						setRenderForm(false);
						const dataForm = {
							name,
							mobile,
							address,
							subDistrict,
						};

						if (!shouldRenderCheckoutForm) {
							setAmplitudeData('Click Checkout', {
								...dataForm,
								userId: mobile,
								storeId,
								notes,
							});
							setAmplitudeUserId(mobile);

							checkoutProduct(dataForm);
						} else {
							setInfoCheckoutForm(dataForm);
						}
					}}
					onBack={() => {
						onHideShowLoader();
					}}
					isCityBounded={isCityBounded}
				/>
			);
		} else if (shouldRenderCheckoutForm && checkoutForm && !token) {
			return (
				<FormCheckout
					checkoutForm={checkoutForm}
					onSubmit={data => {
						let dataForm = {
							...infoCheckoutForm,
							checkout_form: data,
						};
						setShouldRenderCheckoutForm(false);

						setAmplitudeData('Click Checkout', {
							...dataForm,
							userId: infoCheckoutForm.mobile,
							storeId,
							notes,
						});
						setAmplitudeUserId(infoCheckoutForm.mobile);

						checkoutProduct(dataForm);
					}}
				/>
			);
		} else {
			return renderCondition();
		}
	};

	return (
		<div
			className={cx(styles.container, {
				[styles.hide]: !show,
				[styles.bgWhite]: renderForm === true,
				[styles.error]: success === false,
			})}
		>
			{/* {renderForm && !token ? (
				<FormInfo
					cart={cart}
					onClick={(
						name: string,
						mobile: string,
						address: string,
						subDistrict: string,
					) => {
						setRenderForm(false);
						checkoutProduct({
							name,
							mobile,
							address,
							subDistrict,
						});
					}}
					onBack={() => {
						onHideShowLoader();
					}}
					isCityBounded={isCityBounded}
				/>
			) : (
				renderCondition()
			)} */}

			{/* {shouldRenderCheckoutForm && checkoutForm && (
				<FormCheckout checkoutForm={checkoutForm} />
			)} */}
			{renderFormCondition()}
		</div>
	);
};

export default RedirectPopup;
