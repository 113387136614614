import React from 'react';
import cx from 'classnames';
import styles from './index.module.css';

interface IProps {
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	onClick?: () => void;
	withClassName?: string;
}

const CheckoutButton: React.FC<IProps> = ({ icon: Icon, children, onClick, withClassName }) => {
	return (
		<button className={cx(styles.button, withClassName ? withClassName : '')} onClick={onClick}>
			<Icon className={styles.icon} />
			{children}
		</button>
	);
};

export default CheckoutButton;
