import React from 'react';
import cx from 'classnames';
import styles from './index.module.css';
import { ICheckoutForm } from '../../../../../../interface/checkoutForm';

interface IProps {
	val: ICheckoutForm;
	answer: string;
	onChangeInput: (value: string) => void;
	isSubmit: boolean;
	error: string;
	no: number;
}

const SelectBoxForm: React.FC<IProps> = ({ val, answer, onChangeInput, isSubmit, error, no }) => {
	return (
		<>
			<div className={styles.formGroup} id={`no${no}`}>
				<label className={styles.title}>
					{val.title}
					{val.required && <span>*</span>}
				</label>
				{val.description && <label className={styles.subTitle}>{val.description}</label>}

				<div
					className={
						isSubmit && error === 'required'
							? cx(styles.selectWrapper, styles.errorWrapper)
							: cx(styles.selectWrapper)
					}
				>
					<select
						className={styles.select}
						required
						onChange={e => {
							onChangeInput(e.target.value);
						}}
					>
						{val.placeholder ? (
							<option value="" disabled selected={answer === ''} hidden>
								{val.placeholder}
							</option>
						) : (
							<option value="" disabled selected={answer === ''} hidden />
						)}
						{val.value &&
							val.value.length > 0 &&
							val.value.map(v => {
								return (
									<option value={v} selected={answer === v}>
										{v}
									</option>
								);
							})}
					</select>
				</div>
				{isSubmit && error === 'required' && (
					<div className={styles.txtError}>Wajib diisi</div>
				)}
			</div>
		</>
	);
};

export default SelectBoxForm;
