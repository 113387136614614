import React from 'react';
import cx from 'classnames';
import styles from './index.module.css';
import { ReactComponent as EmptySearch } from '../../../assets/icons/emptySearch.svg';

interface IProps {
	query: string;
}

const Expire: React.FC<IProps> = ({ query }) => (
	<div className={cx(styles.wrapper)} id="mtErrorPageEmptyProduct">
		<div className={styles.container}>
			<EmptySearch />
			<div className={styles.txtTitle}>“{query}” tidak dapat ditemukan</div>
			<div className={styles.txtDesc}>
				Coba cari dengan kata lain atau buat tidak terlalu spesifik, barangkali ada!
			</div>
		</div>
	</div>
);

export default Expire;
