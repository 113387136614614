import React, { useState } from 'react';
import cx from 'classnames';

import CheckoutButton from '../CheckoutButton';
import CheckoutPopup from '../CheckoutPopup';
import { ReactComponent as CartIcon } from '../../assets/icons/cart.svg';

import { formatRp } from '../../utils/currency';
import { ICart, IProducts, IProduct } from '../../interface/products';
import { ICheckoutForm } from '../../interface/checkoutForm';
import styles from './index.module.css';

interface IProps {
	products: IProducts[];
	cart: ICart;
	setCart: (cart: ICart) => void;
	bulk?: boolean;
	token?: string;
	isCityBounded: string;
	storeId: string;
	checkoutForm?: ICheckoutForm[];
}

const CartSummary: React.FC<IProps> = ({
	products,
	cart,
	setCart,
	bulk = false,
	token,
	isCityBounded,
	storeId,
	checkoutForm,
}) => {
	const [showPopup, setShowPopup] = useState(false);
	const displayPopup = () => setShowPopup(true);
	const hidePopup = () => setShowPopup(false);

	const getCartItems = () => {
		const matchedItems: IProduct[] = [];
		for (const product of products) {
			const items = product.items.filter(item => cart.items[item.id]);
			matchedItems.push(...items);
		}
		const removeDuplicate: IProduct[] = matchedItems.reduce((unique: any, o) => {
			if (!unique.some((obj: IProduct) => obj.id === o.id && obj.id === o.id)) {
				unique.push(o);
			}
			return unique;
		}, []);

		return removeDuplicate;
	};

	const cartItems = getCartItems();

	const generateTotalPrice = () => {
		let totalPrice = 0;
		let totalPriceBeforeDiscount = 0;

		cartItems.forEach(val => {
			const qtyItem = cart.items[val.id];

			totalPrice += val.price * qtyItem;
			if (val.price_before_discount) {
				totalPriceBeforeDiscount += val.price_before_discount * qtyItem;
			}
		});

		return {
			totalPriceBeforeDiscount,
			totalPrice,
		};
	};
	const { totalPriceBeforeDiscount, totalPrice } = generateTotalPrice();

	const isPopUpShow = showPopup && (cart.totalItems !== 0 || totalPrice !== 0);

	return (
		<>
			<div
				className={cx(styles.container, {
					[styles.hideContainer]: cart.totalItems === 0 || totalPrice === 0,
				})}
			></div>
			<div
				className={cx(styles.floating, {
					[styles.hideButton]: cart.totalItems === 0 || totalPrice === 0 || isPopUpShow,
				})}
				onClick={displayPopup}
			>
				<div className={styles.wrapperBtnCheckout}>
					<CheckoutButton icon={CartIcon} withClassName="mtFloatSummary">
						<span className={styles.totalItem}>{cart.totalItems} item</span>
						<div className={cx(styles.verticalDivider, 'vertical-devider')}></div>
						<span className={styles.totalPrice}>
							{formatRp(totalPrice, { prefix: true })}
						</span>
					</CheckoutButton>
				</div>
			</div>
			<CheckoutPopup
				cart={cart}
				setCart={setCart}
				cartItems={cartItems}
				show={isPopUpShow}
				hidePopup={hidePopup}
				bulk={bulk}
				token={token}
				isCityBounded={isCityBounded}
				storeId={storeId}
				checkoutForm={checkoutForm}
				totalPrice={totalPrice}
				totalPriceBeforeDiscount={totalPriceBeforeDiscount}
			/>
		</>
	);
};

export default CartSummary;
