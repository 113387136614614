import React from 'react';
import cx from 'classnames';
import styles from './index.module.css';
import { ReactComponent as NotFoundSVG } from '../../../assets/icons/notFound.svg';

interface IProps {
	msg?: string;
}

const NotFound: React.FC<IProps> = ({ msg }) => (
	<div className={cx(styles.wrapper)} id="mtErrorPageNotFound">
		<div className={styles.container}>
			<NotFoundSVG />
			<div className={styles.txtTitle}>
				{msg === 'TokenExpiredError'
					? '	Upps, link ini sudah kedaluwarsa!'
					: 'Upps, kami tidak bisa menemukan toko yang kamu cari'}
			</div>
			<div className={styles.txtDesc}>
				{msg === 'TokenExpiredError'
					? 'Silahkan chat ke akun Whatsapp kembali ya untuk mendapatkan link terbaru'
					: 'Periksa link, pastikan sesuai. Jika tidak silakan hubungi penjual dan beritahu bahwa link ini tidak dapat diakses'}
			</div>
			<div className={styles.txtFooter}>
				{msg === 'TokenExpiredError'
					? 'Kode Error : TOKEN_EXPIRED'
					: 'Kode Error : 404 link_not_found'}
			</div>
		</div>
	</div>
);

export default NotFound;
