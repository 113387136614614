import React, { useState } from 'react';
import cx from 'classnames';
import { ICart } from '../../../../interface/products';
import useForm, { SubmitForm as ISubmitForm, ObjectLiteral } from '../../../../hooks/useForm';
import { IFormObject } from '../../../../api/product';
import styles from '../index.module.css';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info.svg';
import { AppStoreGenerateTextError } from '../../../../utils/formValidation';
import { setItem, getItem, constanstMessage } from '../../../../utils/localStorage';
import ReactSuggest from './ReactSuggest';
import {
	appStorePhoneForm as ValidateAppPhone,
	appStoreIsErrorForm,
	appStoreIsErrorFormOnChange,
} from '../../../../utils/formHelper';

interface IProps {
	cart: ICart;
	onClick: (name: string, mobile: string, address: string, subDistrict: string) => void;
	onBack: () => void;
	isCityBounded: string;
}

const oldStoreName = getItem(constanstMessage.SHOP_STORE_NAME) || '';
const oldStoreAddr = getItem(constanstMessage.SHOP_STORE_ADDRESS) || '';
const oldStorePhone = getItem(constanstMessage.SHOP_STORE_PHONE_NUMBER) || '';
const dtStrSubDistrict = getItem(constanstMessage.SHOP_STORE_SUB_DISTRICT_SELECT_OPT) || '';
const subDistrictParse = dtStrSubDistrict;

const oldSelectSubDistrict = subDistrictParse ? subDistrictParse : '';
const isAnyOldAddr = oldStoreName !== '' && oldStorePhone !== '' && oldStoreAddr !== '';

export const initialValueForm = (): IFormObject => {
	if (isAnyOldAddr) {
		return {
			name: oldStoreName,
			mobile: oldStorePhone,
			address: oldStoreAddr,
		};
	}
	return {
		name: '',
		mobile: '',
		address: '',
	};
};

const FormInfo: React.FC<IProps> = ({ cart, onClick, onBack, isCityBounded }) => {
	const [isOldAddr, setIsOldAddr] = useState<boolean>(isAnyOldAddr);
	const [valueSubDistrict, setValueSubDistrict] = useState<string>(
		oldSelectSubDistrict ? oldSelectSubDistrict : '',
	);
	const { values, errors, handleChange, isOnSubmit, handleSubmit } = useForm({
		initialValue: initialValueForm(),
		onSubmit: (dt: ISubmitForm) => {
			const subDistrictError = isCityBounded !== '' ? valueSubDistrict !== '' : true;
			if (!dt.isAnyErrors && subDistrictError) {
				const dtName = dt.values.name;
				const dtMobile = dt.values.mobile;
				const dtAddr = dt.values.address;

				setItem(constanstMessage.SHOP_STORE_NAME, dtName);
				setItem(constanstMessage.SHOP_STORE_PHONE_NUMBER, dtMobile);
				setItem(constanstMessage.SHOP_STORE_ADDRESS, dtAddr);
				isCityBounded &&
					setItem(constanstMessage.SHOP_STORE_SUB_DISTRICT_SELECT_OPT, valueSubDistrict);

				onClick(dtName, dtMobile, dtAddr, valueSubDistrict);
			}
		},
		withCustomValidation: (errValue: ObjectLiteral, formValue: ObjectLiteral) =>
			ValidateAppPhone(errValue, formValue),
	});

	const isErrorPhoneForm = () => {
		return appStoreIsErrorForm(errors.mobile, isOnSubmit);
	};

	const isErrorPhoneFormOnChange = () => {
		return appStoreIsErrorFormOnChange(errors.mobile, values.mobile);
	};

	const generateTextPhoneError = () => {
		return <AppStoreGenerateTextError mobile={errors.mobile} />;
	};

	const renderForm = () => (
		<form className={cx(styles.formContainer, 'mtFormUserData')} onSubmit={handleSubmit}>
			<div className={styles.infoContainer}>
				<InfoIcon className={styles.infoIcon} />
				<div className={styles.infoText}>
					<div className={styles.infoTitle}>Sedikit lagi...</div>
					<div className={styles.infoDesc}>
						Sebelum proses selanjutnya, yuk isi data dibawah ini sebentar, supaya kita
						bisa proses
					</div>
				</div>
			</div>
			<div className={styles.formGroup}>
				<label htmlFor="nameInput">Nama</label>
				<input
					type="text"
					id="nameInput"
					name="name"
					placeholder="Masukan nama lengkap disini..."
					value={values.name}
					onChange={handleChange}
					className={errors.name === 'required' ? styles.invalidForm : ''}
				/>
				{errors.name && (
					<div className={styles.txtError}>
						{errors.name === 'required' && 'Wajib diisi'}
					</div>
				)}
			</div>
			<div className={styles.formGroup}>
				<label htmlFor="noHP">Nomor HP</label>
				<input
					type="text"
					pattern="[0-9+-\s]*"
					id="noHP"
					name="mobile"
					placeholder="Contoh : 082xxxxxxxx"
					value={values.mobile}
					onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
						e.target.value = e.target.value.trim();
						handleChange(e);
					}}
					className={isErrorPhoneForm() ? styles.invalidForm : ''}
				/>
				{isErrorPhoneForm() && (
					<div className={styles.txtError}>{generateTextPhoneError()}</div>
				)}
				{isErrorPhoneFormOnChange() && !isErrorPhoneForm() && (
					<div className={styles.txtErrorOnChange}>{generateTextPhoneError()}</div>
				)}
			</div>
			{isCityBounded !== '' && (
				<div className={styles.formGroup}>
					<label>Kelurahan/Desa</label>
					<ReactSuggest
						isCityBounded={isCityBounded}
						onChange={(dt: string) => {
							setValueSubDistrict(dt);
						}}
						currentValue={valueSubDistrict}
						isError={isOnSubmit && valueSubDistrict === ''}
					/>
					<div className={styles.txtSubDistrict}>
						<span>Khusus: {isCityBounded}</span>
					</div>
					{isOnSubmit && valueSubDistrict === '' && (
						<div className={styles.txtError}>Wajib diisi</div>
					)}
				</div>
			)}

			<div className={styles.formGroup}>
				<label htmlFor="addressInput">Alamat</label>

				<textarea
					id="addressInput"
					onChange={handleChange}
					className={errors.address === 'required' ? styles.invalidForm : ''}
					name="address"
					rows={4}
					placeholder="Tulis nama jalan lengkap dengan nomor rumah, RT RW, kelurahan, kecamatan serta kode pos...."
				>
					{values.address}
				</textarea>
				{errors.address && (
					<div className={styles.txtError}>
						{errors.address === 'required' && 'Wajib diisi'}
					</div>
				)}
			</div>
			<div className={styles.btnContainer}>
				<button
					type="button"
					className={cx(styles.backButton, 'mtFormBack')}
					onClick={onBack}
				>
					Kembali
				</button>
				<button type="submit" className={cx(styles.submitButton, 'mtFormConfirm')}>
					Konfirmasi
				</button>
			</div>
		</form>
	);

	const renderOldAddress = () => (
		<div className={cx(styles.formContainer)}>
			<div className={styles.infoContainer}>
				<InfoIcon className={styles.infoIcon} />
				<div className={styles.infoText}>
					<div className={styles.infoTitle}>Apakah ini kamu?</div>
					<div className={styles.infoDesc}>
						Sebelumnya kami mendeteksi kamu telah mengisi ini, silakan konfirmasi atau
						ubah
					</div>
				</div>
			</div>
			<div className={styles.oldAddrContainer}>
				{' '}
				<div className={styles.oldName}>{`${oldStoreName} (${oldStorePhone})`}</div>
				<div className={styles.oldAddr}>{oldStoreAddr}</div>
				{isCityBounded !== '' && (
					<div className={styles.oldAddr}>{oldSelectSubDistrict}</div>
				)}
				<div className={styles.changeAddrWrapper}>
					<button
						type="button"
						onClick={() => {
							setIsOldAddr(false);
						}}
					>
						Ubah
					</button>
				</div>
			</div>
			<div className={styles.btnContainer}>
				<button
					type="button"
					className={cx(styles.backButton, 'mtFormBack')}
					onClick={onBack}
				>
					Kembali
				</button>
				<button
					type="button"
					className={cx(styles.submitButton, 'mtFormConfirm')}
					onClick={() => {
						onClick(oldStoreName, oldStorePhone, oldStoreAddr, oldSelectSubDistrict);
						// onClick(oldStoreName, oldStorePhone, oldStoreAddr);
					}}
				>
					Konfirmasi
				</button>
			</div>
		</div>
	);

	if (isOldAddr) {
		return renderOldAddress();
	}

	return renderForm();
};

export default FormInfo;
