export const trimOn = (str: string, on: number) => {
	if (str && str.length > on) {
		const arr = str.slice(0, on);
		const name = `${arr}...`;
		return name;
	}
	return str;
};

export const replaceAll = (str: string, find: string, replace: string) => {
	return str.replace(new RegExp(find, 'g'), replace);
};
