import styled from 'styled-components';

const GlobalThemeReplacer = styled.div`
	.mtBtnCategory path.chatnshop-catBtnBg-txt {
		fill: var(--chatnshop-catBtnColor);
	}
	@media (hover: hover) {
		.mtBtnCategory:hover path.chatnshop-catBtnBg-txt {
			fill: var(--chatnshop-btnBg-hover-txt);
		}
	}
	.mtBtnCategory:active path.chatnshop-catBtnBg-txt {
		fill: var(--chatnshop-catBtnColor);
	}
	.mtFloatSummary path.chatnshop-color-secondary-txt,
	.mtFloatSummary circle.chatnshop-color-secondary-txt {
		stroke: var(--chatnshop-btnColor);
	}
	@media (hover: hover) {
		.mtFloatSummary:hover path.chatnshop-color-secondary-txt,
		.mtFloatSummary:hover circle.chatnshop-color-secondary-txt {
			stroke: var(--chatnshop-btnBg-hover-txt);
		}
	}

	.mtFloatSummary:active path.chatnshop-color-secondary-txt,
	.mtFloatSummary:active circle.chatnshop-color-secondary-txt {
		stroke: var(--chatnshop-btnBg-active-txt);
	}

	@media (hover: hover) {
		.mtFloatSummary:hover .vertical-devider {
			background-color: var(--chatnshop-btnBg-hover-txt);
			border-right: 1px solid var(--chatnshop-btnBg-hover-txt);
		}
	}

	.mtFloatSummary:active .vertical-devider {
		background-color: var(--chatnshop-btnBg-active-txt);
		border-right: 1px solid var(--chatnshop-btnBg-active-txt);
	}

	.mtSendCheckout path.chatnshop-color-secondary-txt {
		fill: var(--chatnshop-btnColor);
	}
	@media (hover: hover) {
		.mtSendCheckout:hover path.chatnshop-color-secondary-txt {
			fill: #fff;
		}
	}

	.react-datepicker-popper {
		z-index: 999;
	}
`;

export default GlobalThemeReplacer;
