import React from 'react';
import cx from 'classnames';
import DatePicker, { registerLocale } from 'react-datepicker';

import id from 'date-fns/locale/id';
import styles from './index.module.css';
import { ICheckoutForm } from '../../../../../../interface/checkoutForm';

registerLocale('id', id);

interface IProps {
	val: ICheckoutForm;
	answer: string;
	onChange: (date: string) => void;
	isSubmit: boolean;
	error: string;
	no: number;
}

const DatepickerForm: React.FC<IProps> = ({ val, onChange, answer, isSubmit, error, no }) => {
	return (
		<>
			<div className={styles.formGroup} id={`no${no}`}>
				<label className={styles.title}>
					{val.title}
					{val.required && <span>*</span>}
				</label>
				{val.description && <label className={styles.subTitle}>{val.description}</label>}

				<div
					className={
						isSubmit && error === 'required'
							? cx(styles.pickerWrapper, styles.errorWrapper)
							: styles.pickerWrapper
					}
				>
					<div className={styles.datepickerWrapper}>
						<DatePicker
							locale="id"
							selected={answer !== '' ? new Date(answer) : ''}
							onChange={(date: any) => {
								onChange(date);
							}}
							placeholderText={val.placeholder || 'DD/MM/YYYY'}
							shouldCloseOnSelect={true}
						/>
					</div>
					{isSubmit && error === 'required' && (
						<div className={styles.txtError}>Wajib diisi</div>
					)}
				</div>
			</div>
		</>
	);
};

export default DatepickerForm;
