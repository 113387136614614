import React from 'react';
import Autosuggest from 'react-autosuggest';
import { getInputKelurahan, Result as ResultInputKelurahan } from '../../../../../api/wilayah';
import { SuggestWrapper } from './styles';

export interface SelectOption {
	name: string;
}

const generateKelurahan = (sk: string) => {
	const str = sk.replace('KELURAHAN-', '');
	const data = str.split('#');
	return data[0];
};

const getSuggestionValue = (suggestion: any) => {
	return suggestion.name;
};

const renderSuggestion = (suggestion: any) => {
	return <span>{suggestion.name}</span>;
};

interface IProps {
	isCityBounded: string;
	onChange: (data: any) => void;
	currentValue: string;
	isError: boolean;
}

interface IState {
	value: string;
	suggestions: SelectOption[];
}

class ReactSuggest extends React.Component<IProps, IState> {
	state = {
		value: '',
		suggestions: [],
	};

	componentDidMount() {
		this.setState({
			value: this.props.currentValue,
		});
	}

	onChange = (event: any, { newValue }: any) => {
		this.props.onChange(newValue);
		this.setState({
			value: newValue,
		});
	};

	onSuggestionsFetchRequested = async ({ value }: any) => {
		if (value.length > 2) {
			const { status, data } = await getInputKelurahan(
				this.props.isCityBounded,
				value ? value : '',
			);

			if (status === 200 && data.results.length > 0) {
				const newData: SelectOption[] = [];
				data.results.forEach((dt: ResultInputKelurahan) => {
					newData.push({
						name: `${generateKelurahan(dt.sk)} (${dt.kota})`,
					});
				});
				this.setState({
					suggestions: newData,
				});
			} else {
				this.setState({
					suggestions: [],
				});
			}
		} else {
			this.setState({
				suggestions: [],
			});
		}
	};

	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: [],
		});
	};

	render() {
		const { value, suggestions } = this.state;
		const { isError } = this.props;

		const inputProps = {
			placeholder: 'Contoh : Lebakwangi',
			value,
			onChange: this.onChange,
		};

		return (
			<SuggestWrapper className={isError ? 'error' : ''}>
				<Autosuggest
					suggestions={suggestions}
					onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
					onSuggestionsClearRequested={this.onSuggestionsClearRequested}
					getSuggestionValue={getSuggestionValue}
					renderSuggestion={renderSuggestion}
					inputProps={inputProps}
				/>
			</SuggestWrapper>
		);
	}
}

export default ReactSuggest;
