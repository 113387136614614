import React from 'react';
import cx from 'classnames';
import styles from './index.module.css';
import { ReactComponent as LoadFailSVG } from '../../../assets/icons/LoadFail.svg';
import { ReactComponent as WhatsappWhiteSVG } from '../../../assets/icons/whatsappWhite.svg';

interface IProps {
	store_name: string;
	store_whatsapp_number: string;
	errorName: string;
}

const LoadFail: React.FC<IProps> = ({ store_name, store_whatsapp_number, errorName }) => (
	<>
		<div className={cx(styles.wrapper)} id="mtErrorPageLoadFail">
			<div className={styles.storeName}>{store_name}</div>
			<div className={styles.container}>
				<LoadFailSVG className={styles.svgFailLoad} />
				<div className={styles.txtTitle}>Menu nya lagi disiapin nih</div>
				<div className={styles.txtDesc}>
					Katalognya saat ini sedang tidak bisa diakses. Tapi jangan khawatir, sambil
					menunggu kamu bisa chat langsung ke penjual
				</div>
				<div className={styles.txtFooter}>Kode Error : {errorName}</div>
			</div>
			{store_whatsapp_number && (
				<button
					type="button"
					className={cx(styles.btnOrderNow, 'mtBtnLoadFailOrder')}
					onClick={() => {
						window.open(
							`https://api.whatsapp.com/send?phone=${store_whatsapp_number}&text=Halo%20${store_name}%0aSaya%20lihat%20menu%20ChatNShop%20masih%20sedang%20disiapkan%20ya?%0aSaya%20ingin%20chat%20terlebih dahulu.%0a`,
							'_blank',
						);
					}}
				>
					<WhatsappWhiteSVG />
					Order langsung ke Penjual
				</button>
			)}
		</div>
	</>
);

export default LoadFail;
