import React from 'react';
import styles from './index.module.css';
import { ICheckoutForm } from '../../../../../../interface/checkoutForm';

interface IProps {
	val: ICheckoutForm;
	answer: string;
	onChangeInput: (value: string) => void;
	isSubmit: boolean;
	error: string;
	no: number;
}

const RadioForm: React.FC<IProps> = ({ val, no, answer, onChangeInput, isSubmit, error }) => {
	return (
		<>
			<div className={styles.formGroup} id={`no${no}`}>
				<label className={styles.title}>
					{val.title}
					{val.required && <span>*</span>}
				</label>
				{val.description && <label className={styles.subTitle}>{val.description}</label>}
				<div className={styles.containerWrapper}>
					{val.value &&
						val.value.length > 0 &&
						val.value.map(v => {
							return (
								<label className={styles.container}>
									{v}
									<input
										type="radio"
										name={String(no)}
										value={v}
										checked={answer === v}
										onClick={() => {
											onChangeInput(v);
										}}
									/>
									<span className={styles.checkmark}></span>
								</label>
							);
						})}
				</div>
				{isSubmit && error === 'required' && (
					<div className={styles.txtError}>Wajib diisi</div>
				)}
			</div>
		</>
	);
};

export default RadioForm;
